/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Buttons!
 *
 * * * * * * * * * * * * * * * * * * * * */

input[type="submit"],
.button {
  // Create a base button style
  @include button();
  @include cga-button();
}

.cta-button {
  @include default-cta-button($type: default);
}

.cta-button-gold {
  @include default-cta-button($type: gold);
}

.button-icon-prefix {
  @include button();
  @include icon-inline-attach($icon-circle-arrow-up, 20);
}

.share-icon-link {
  @include icon-inline-attach($icon-share, 16);
}

.testify-icon-link {
  @include icon-inline-attach($icon-testify, 16);
}

.audio-icon-link {
  @include icon-inline-attach($icon-speaker-volume-up, 16)
}


/**
 * Animated Menu Toggle
 */
$toggle-transition-speed: 0.3s;
.animated-toggle {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;

  .lines {
    display: block;
    position: absolute;
    top: 13px;
    left: 0;
    height: 4px;
    width: 30px;
    border-radius: 2px;
    background: $primary-color;
    transition: background 0s $toggle-transition-speed;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      height: 4px;
      width: 30px;
      border-radius: 2px;
      transition-duration: $toggle-transition-speed, $toggle-transition-speed;
      transition-delay: $toggle-transition-speed, 0s;
    }
    &:before {
      top: -9px;
      background: $primary-color;
      transition-property: top, transform;
    }
    &:after{
      bottom: -9px;
      background: $primary-color;
      transition-property: bottom, transform;
    }
  }
}
.main-mobile-menu-open {
  .mobile-main-menu-toggle {
    .animated-toggle {
      .lines {
        background: transparent;
        &:before {
          top: 0;
          transform: rotate(45deg);
        }
        &:after {
          bottom: 0;
          transform: rotate(-45deg);
        }
        &:before,
        &:after {
          transition-delay: 0s, $toggle-transition-speed;
        }
      }
    }
  }
}
