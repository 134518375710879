/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All footer styles
 *
 * * * * * * * * * * * * * * * * * * * * */

.footer {
  @include vertical-space(default, top);
}

.footer-bottom {
  clear: both;
  background-color: $base-gray-lightest;
  padding: 30px 0;
}
.footer-content-wrapper {
  @include clearfix();
  @include content-width();

  .menu .menu__item {
    display: block;
    & > a {
      padding: 8px 0;
      font-size: 14px;
      color: $medium-font-gray;
    }
  }
  .block-menu-block .block-title {
    @include small-header();
  }

  #block-bean-footer-copy {
    .block-title-container {
      display: table;
      min-height: 50px;
      margin-bottom: 10px;
    }
    .block-title {
      display: table-cell;
      vertical-align: middle;
      padding-left: 55px;
      font-size: 22px;
      line-height: 1.1;
      min-height: 50px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        height: 50px;
        width: 50px;
        left: 0;
        top: 0;
        background-image: $image-cga-logo;
        background-size: 50px 50px;
        background-repeat: no-repeat;
      }
    }
  }
}
.some-footer-content {
  //@include callout(pink);
}

.footer-content {
  @include breakpoint(medium) {
    @include block-grid($number: 2, $selector: '.region');
  }
  @include breakpoint(large) {
    @include block-grid($number: 4, $selector: '.region');
  }
}