/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All footer styles
 *
 * * * * * * * * * * * * * * * * * * * * */

input:not([type="submit"]),
textarea,
select {
  width: 100%;
}

fieldset.webform-component--request-a-time {
  .day {
      width: 25%;
  }

  .year{
      width: 25%;
  }

  .month {
      width: 25%;
  }
}

input,
textarea {
  font-family: $sans-font-stack;
  border: 1px solid $base-gray-light;
}

select:not([multiple]) {
  padding-right: 32px;
  background-size: 28px 24px;
  background-origin: padding-box;
  @include select-dropdown-background($border-dark-gray);
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M224 387.814V512L32 320l192-192v126.912C447.375 260.152 437.794 103.016 380.93 0 521.287 151.707 491.48 394.785 224 387.814z'/%3E%3C/svg%3E");
  .lt-ie10 & {
    background-image: none;
    padding-right: 8px;
  }
}

optgroup {
  font-weight: normal;
  font-style: italic;
  color: $light-font-gray;
  option {
    color: $body-font-color;
  }
}

select[multiple] {
  background-image: none;
  display: none;
}

.description {
  font-family: $sans-font-stack;
  color: lighten($body-font-color, 25%);
  padding: 3px 0;
}

label {
  font-family: $sans-font-stack;
}

#{text-input-types()} {
  @include form-element;
  -webkit-appearance: none;
  -moz-appearance: none;
}


/**
 * Select2
 */
.select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 0;
}



/**
 * iCheck styles
 */

// This is our form-item wrapper class, below is default drupal, adjust as necessary
.form-type-checkbox,
.form-type-radio {
  position: relative;
  padding-left: 25px;
  margin: 10px 0;
}
.icheckbox-default,
.iradio-default {
  position: absolute;
  left: 0;
  top: 2px;
  height: 18px;
  width: 18px;
  display: inline-block;
  border: 2px solid $primary-color;
  transition: background-color 300ms;
}
.iradio-default {
  border-radius: $global-rounded;
  &.hover {
    background-color: rgba($primary-color, 0.2);
  }
  &.checked {
    background-color: rgba($primary-color, 1);
  }
}
.icheckbox-default {
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    transition: background-color 300ms;
  }
  &.hover:before {
    background-color: rgba($primary-color, 0.2);
  }
  &.checked:before {
    background-color: rgba($primary-color, 1);
  }
}
.icheck-label {
  line-height: 1.2;
}



/**
 * Relations admin forms sections
 */
.page-node-relations .main-content form {
  @include callout();
}


/**
 * Drupal form related defaults
 */
.views-exposed-widgets label {
  font-weight: $global-weight-normal;
  @include small-label-font-styles();
}


/**
 * Some drupal forms
 */
#user-login {
  input {
    margin-bottom: 0;
  }
  input[type="password"] {
    letter-spacing: 2px;
  }
}
