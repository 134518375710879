


.node-book {
  .book-navigation{
    .menu{
      .menu__item{
        display: inline;
      }
    }
  }
}
