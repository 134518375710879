/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Default styles retained from Zen
 * Default drupal related styles
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Comments
 */

.comment { /* Wrapper for a single comment */
  &.comment-preview { /* Preview of the comment before submitting new or updated comment */
    background-color: #ffffea; /* Drupal core will use a #ffffea background. See #1110842 */
  }
}

.comment-unpublished { /* Unpublished comments */
  /* background-color: #fff4f4; */ /* Drupal core uses a #fff4f4 background */

  p.unpublished { /* The word "Unpublished" displayed underneath the content. */
    @include unpublished-div;
  }
}

/**
 * Nodes
 */

.node-unpublished { /* Unpublished nodes */
  /* background-color: #fff4f4; */ /* Drupal core uses a #fff4f4 background */

  p.unpublished { /* The word "Unpublished" displayed underneath the content. */
    @include unpublished-div;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 * See the element-focusable rule in system.base.css.
 */

#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}


/**
 * Custom styles for Drupal's admin tabs.
 */

ul.primary {
  font-size: 16px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  position: relative;
  z-index: z(defined);
  background: $color-tabs-wrap-background;
  border-bottom: 1px solid $color-tabs-wrap-border;
  li {
    list-style: none;
    float: left;
    margin-bottom: 0;
    a {
      padding: 8px 18px;
      background: $color-tabs-link-background;
      border-top: 5px solid #ffd204;
      display: block;
      color: $color-tabs-link;
      font-weight: normal;
      text-decoration: none;
      &:hover,
      &:active {
        background: $color-tabs-link-hover-background;
        color: $color-tabs-link-hover;
      }
    }
  }
  li:nth-child(odd){
    a {
      border-top: 5px solid $color-tabs-link-alt-1;
      &:hover,
      &:active {
        background: $color-tabs-link-alt-1;
        color: #ffffff;
      }
    }
  }
  li:nth-child(even) {
    a {
      border-top: 5px solid $color-tabs-link-alt-2;
      &:hover,
      &:active {
        background: $color-tabs-link-alt-2;
        color: #ffffff;
      }
    }
  }
  li:nth-child(3n) {
    a {
      border-top: 5px solid $color-tabs-link-alt-3;
      &:hover,
      &:active {
        background: $color-tabs-link-alt-3;
        color: #ffffff;
      }
    }
  }
  li:nth-child(4n) {
    a {
      border-top: 5px solid $color-tabs-link-alt-4;
      &:hover,
      &:active {
        background: $color-tabs-link-alt-4;
        color: #ffffff;
      }
    }
  }
}


/**
 * Custom styles for Drupal's notification messages.
 * @TODO: Add the icons as fonts when an icon font is added
 */

.messages {
  color: #fff;
  margin: 6px 0;
  padding: 10px 10px 10px 40px; /* LTR */
  position: relative;
  text-align: left;
  overflow: hidden;

  &:before {
    @include square(24px);
    content: '';
    font-size: 22px;
    position: absolute;
    left: 10px;
    top: 7px;
  }
}

.status,
.ok {
  background-color: $statusokbg;
  a {
    color: lighten($statusokbg, 50%);
  }
  &:before {
    content: $icon-status;
    @include icomoon();
  }
}

.warning {
  background-color: $warningbg;
  a {
    color: lighten($warningbg, 50%);
  }
  &:before {
    content: $icon-warning;
    @include icomoon();
  }
}

.error {
  background-color: $errorbg;
  a {
    color: lighten($errorbg, 50%);
  }
  &:before {
    content: $icon-error;
    @include icomoon();
  }
}

.messages ul {
  list-style: none;
  margin: 0; /* LTR */
  padding: 0;
}
.messages ul li {
  border-bottom: 1px solid #fff;
  list-style-image: none;
  margin-bottom: 4px;
  padding-bottom: 5px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
// Fix some styles in Krumo
.krumo-element,
.krumo-footnote,
.krumo-call,
.krumo-preview {
  color: #231f20;
}

/**
 * Correction for the admin bar style
 */
#admin-menu,
#admin-menu .dropdown {
  z-index: z(drupal-admin);
}
#admin-menu-wrapper {
  // Comment back in if going back to default drupal admin theme
  //li,
  //a {
  //  font-size: 11px;
  //  line-height: 14px;
  //}
  //.admin-menu-search {
  //  height: auto;
  //}
  //.toolbar-shortcuts a {
  //  line-height: 24px;
  //}
}
.slicknav_menu {
  position: relative;
  z-index: z(drupal-admin);
  a.slicknav_item {
    font-size: 14px;
  }
}
@include breakpoint($adminimal-menu-breakpoint down) {
  body.admin-menu.adminimal-menu:before {
    height: 0;
  }
}


/**
 * Contextual Links fix for inside menu blocks
 */
.block-menu-block.contextual-links-region .contextual-links-wrapper .contextual-links {
  min-width: 130px;
  padding: 2px 0;
  li {
    background: transparent;
    float: none;
  }
  a {
    background: transparent;
    line-height: 1;
    display: block;
    padding: 2px 15px 2px 5px;
    &:hover {
      background: #bfdcee;
      background-color: #bfdcee;
    }
  }
}