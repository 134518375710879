/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All footer styles
 *
 * * * * * * * * * * * * * * * * * * * * */

/**
 * General block styles acroos many use cases
 */
.block-title {
  @include title-space(section-title);
}
// Twitter block
.block-twitter-block {
  border: 2px solid $base-gray-light;
}

/**
 * Specific block styles based on id
 */
// Home schedule block

#block-cga-sessions-teaser-house-schedule, #block-cga-sessions-teaser-senate-schedule   {
 .chamber-schedule {
    width: 100% !important;
    padding: 10px;
  }
  margin-top: 50px;
}

#block-cga-sessions-teaser-chamber-schedule, #block-cga-sessions-teaser-house-schedule, #block-cga-sessions-teaser-senate-schedule,  #block-bean-chamber-publications-block, #block-cga-sessions-session-documents {
  &:not(:last-child) {
    @include vertical-space(default);
  }
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
    margin-bottom: 40px;
  }

  .schedules {
    & > div {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include breakpoint(medium) {
      @include block-grid(2, 'div', 30, 50px);
    }
  }
  .schedule-header {
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 2px solid;
    h3 {
      margin: 0;
      font-family: $sans-font-stack;
      display: inline-block;
    }
    .teaser-schedule-header-wrapper{
//    .field-name-field-senate-calendar,
//    .field-name-field-house-calendar{
      float: right;
      margin: 12px 0 0 10px;
      font-family: $sans-font-stack;
      font-size: 14px;
      &:last-child {
        margin-left: 0;
      }
        .field-name-field-senate-calendar,
        .field-name-field-file-non-searchable,
        .field-name-field-house-calendar {
        a {
          @include icon-inline-attach($icon-calendar, 14, before);
       }
    }

    }
    .teaser-schedule-header-wrapper {
      #watch-listen-link {
        @include icon-inline-attach($icon-video, 16, before);
      }
    }
    @include breakpoint(medium down) {
      .field-name-field-house-calendar,
      .field-name-field-senate-calendar,
      .field-name-field-file-non-searchable,
      .teaser-schedule-header-wrapper {
        margin-top: 0;
        a {
          width: 0;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 35px;
          height: 40px;
          margin: 0 0 0 10px;
          &:before,
          &:before {
            font-size: 22px;
          }
        }
      }
    }
  }

  .senate-schedule {
    .schedule-header {
      border-color: $senate-color;
    }
  }
  .house-schedule {
    .schedule-header {
      border-color: $house-color;
    }
  }

  .schedule-body {
    $time-width-small: 120px;
    $time-width: 200px;
    margin-bottom: 25px;
    @include clearfix();
    .time,
    .title {
      display: block;
      float: left;
      line-height: 1.1;
    }
    .time {
      width: $time-width-small;
      padding-right: 10px;
      margin-top: 1px;

      font-family: $sans-font-stack;
      color: $light-font-gray;
      font-size: 15px;
      text-align: right;
      transition: color $default-transition-length;
      @include breakpoint(medium) {
        padding-right: 20px;
        width: $time-width;
      }
      @include breakpoint(large) {
        font-size: 17px;
      }
    }
    .title {
      width: calc(100% - #{$time-width-small});
      color: $font-gray;
      font-size: 16px;
      transition: color $default-transition-length;
      @include breakpoint(medium) {
        width: calc(100% - #{$time-width});
      }
      @include breakpoint(large) {
        font-size: 18px;
      }
    }
    .schedule-item {
      display: block;
      margin-bottom: 10px;
      @include clearfix();
      &:last-child {
        margin-bottom: 0;
      }
      @include breakpoint(medium) {
        margin-bottom: 15px;
      }
    }
    a.schedule-item:hover {
      .time,
      .title {
        color: $primary-color;
      }
    }
  }
  .full-schedule-link {
    @include default-cta-button(gold);
  }
  .no-upcoming-activities {
    margin: 0;
    font-style: italic;
  }
}

// Session schedule chamber calendars
#block-cga-sessions-session-schedule-calendars, #block-cga-sessions-house-schedule-calendar, #block-cga-sessions-senate-schedule-calendar {
  .block-content {
    @include clearfix();
  }
  .field-type-file {
    float: left;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    a {
      @include default-cta-button(gold);
    }
  }
}

.view-interim-schedule{
  .more-link{
    a{
      @include default-cta-button(gold);
      float:left;
    }
  }
}

/**
 * Shared Blocks
 */
#block-cga-blocks-field-bottom {
  @include content-width(window);
  @include vertical-space();
}

#block-cga-cta-defaults-custom-ctas {
  .region-footer & {
    @include content-width();
    margin-bottom: 30px;
  }
}

// Actions
#block-cga-blocks-actions {
  @include clearfix();
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  padding: 0 15px;
  @include breakpoint(large) {
    position: static;
    top: 0;
    padding: 0;
    @include vertical-space(small);
  }
  .action-item {
    float: right;
    margin-right: 15px;
    a {
      font-family: $sans-font-stack;
    }
    &:first-child {
      margin-right: 0;
    }
    .share-icon-link {
      padding-left: 30px;
    }
    .share-icon-link,
    .testify-icon-link {
      text-decoration: underline;
    }
  }
}


// Committee overview filter
#block-views-exp-committees-overview-main {
  @include breakpoint(large) {
    &:last-child {
      text-align: right;
      label {
        text-align: left;
      }
    }
  }

  .views-exposed-widgets {
    display: inline-block;
    position: relative;
    margin: 0;
  }
  select {
    margin: 0;
    width: auto;
  }
  @include breakpoint(large) {
    @include vertical-space(medium);
  }
  .block-title {
    @include small-label-font-styles();
  }
  .views-exposed-widget {
    padding: 0;
    float: none;
    .ajax-progress-throbber {
      position: absolute;
      right: -25px;
      bottom: 9px;
    }
  }
}

// Committee detail view-by-session
#block-cga-committees-committee-sessions {
  @include vertical-space(small);
  @include breakpoint(large) {
    @include vertical-space(medium);
  }
  select {
    margin: 0;
    width: auto;
  }
  .block-title {
    @include small-label-font-styles();
    margin: 0;
  }
}

#block-cga-committees-committee-members {
  @include content-width(breakout);
  @include vertical-space();
  .block-container {
    @include breakout-container();
  }
  .block-title-container {
    text-align: center;
  }
  .block-header {
    margin-bottom: 30px;
  }
  .block-title {
    @include block-header();
  }
  .block-content {
    position: relative;
  }
  .session-select {
    @include breakpoint(large) {
      position: absolute;
      top: 10px;
      right: 0;
    }
    label {
      display: inline;
      padding-right: 10px;
    }
    select {
      width: auto;
      margin: 0;
    }
  }
  .members {
    .roster-item {
      margin-bottom: 15px;
    }
    @include breakpoint(medium) {
      @include block-grid(2, '.roster-item', $bottom-gutter: 25);
    }
    @include breakpoint(large) {
      @include block-grid(3, '.roster-item', $bottom-gutter: 25);
    }
  }
  .member {
    @include member-layout();
  }
  .member-details {
    h4 {
      margin: 0;
    }
  }
  .member-title,
  .member-role {
    @include small-label-font-styles();
    display: block;
    line-height: 1;
  }
  .member-role {
    font-size: 16px;
  }
}
// Meeting info block
#block-cga-committees-committee-meetings {
  @include breakout-container();
  .contact-wrapper {
    margin-top: 30px;
  }
  h2 {
    font-family: $sans-font-stack;
    font-size: 18px;
    text-transform: uppercase;
    color: $light-font-gray;
  }
  h3 {
    @include small-label-font-styles(uppercase);
    margin-bottom: 0;
    border-bottom: none;
  }
  .staff-email a {
    @include breakout-cta-all-defaults();
  }
}
#block-views-committees-overview-main {
  .view-committees-overview > .view-content {
    & > .views-row {
      margin-bottom: 15px;
    }
    @include breakpoint(large) {
      @include block-grid(2, '.views-row');
    }
  }
  .attachment-after {
    & > .view-committees-overview {
      margin-top: 40px;
    }
  }
  .view-empty {
    font-style: italic;
  }
  .view-header {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $base-gray-light;
    h3 {
      font-family: $sans-font-stack;
    }
    p {
      font-family: $sans-font-stack;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .views-field-title {
    .field-content {
      display: block;
      line-height: 1.1;
      a {   
        @include header-font-size(h4);
        line-height: 1.1;
      }
    }
  }
}
#block-cga-committees-committee-schedule-items {
  @include vertical-space();
  .block-title {
    font-family: $sans-font-stack;
  }
  .node-schedule-item.node-teaser {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $base-gray-medium;
    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }
}

#block-cga-committees-committee-activity {
  .block-title {
    font-family: $sans-font-stack;
  }

  .accordion-title {
    .field-name-field-date {
      @include inline-drupal-field-items('');
    }
  }

  @include vertical-space();
  .activity-meta {
    margin-bottom: 5px;
    @include clearfix();
    @include breakpoint(medium) {
      $document-width: 150px;
      .description-wrapper {
        float: left;
        width: calc(100% - #{$document-width});
      }
      .documents-wrapper {
        float: right;
        width: #{$document-width};
        text-align: right;
        a {
          font-family: $sans-font-stack;
          font-size: 14px;
          @include icon-inline-attach($icon-document, 14)
        }
      }
    }
  }

  .committee-activity-documents {
    div {
      display: inline;
    }
    & > .field {
      &:after {
        content: ' | ';
      }
      &:last-child:after {
        content: '';
        display: none;
      }
    }
  }
}


/**
 * Publication Blocks
 */
#block-cga-publications-popular-publications {
  @include content-width(breakout);
  @include vertical-space(default);
  .block-container {
    @include breakout-container();
  }
  .block-content {
    @include block-grid($number: 1, $selector: 'article');
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: 'article');
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: 'article');
    }
  }
  article {
    margin-top: 0;
  }
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
  }
  .breakout-cta {
    @include breakout-cta-all-defaults();
  }
}
// Search terms for `type` for publications
#block-cga-publications-publication-search-type {
  @include content-width(breakout);
  @include vertical-space(default);
  .block-container {
    @include breakout-container();
  }
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
  }
  .publication-types {
    list-style-type: none;
    @include block-grid($number: 1, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    @include breakpoint(small) {
      @include block-grid($number: 2, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(medium) {
      @include block-grid($number: 3, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(large) {
      @include block-grid($number: 4, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    }
  }
  a {
    font-family: $sans-font-stack;
  }
}


/**
 * Bill Related Blocks
 */
#block-cga-publications-publication-search-recourse-book,
#block-cga-publications-publication-search-issue-brief,
#block-cga-publications-publication-search-subject-terms,
#block-cga-bills-bill-search-subject-terms, 
#block-cga-publications-publication-search-legislation{
  @include content-width(breakout);
  @include vertical-space(default);
  .block-container {
    @include breakout-container();
  }
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
  }
  .block-subjects {
    list-style-type: none;

    @include block-grid($number: 1, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    @include breakpoint(small) {
      @include block-grid($number: 2, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(medium) {
      @include block-grid($number: 3, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(large) {
      @include block-grid($number: 4, $selector: 'li', $bottom-gutter: 15, $gutter: 30px);
    }
  }
  .term-wrapper {
    position: relative;
    font-family: $sans-font-stack;
    height: 50px;
    @include breakpoint(small) {
      height: 70px;
    }
  }
  .term-link {
    @include v-align-transform();
    padding-left: 40px;
    line-height: 1.2;
    font-size: 16px;
    &:before {
      display: block;
      @include v-align-transform();
      left: 0;

      @include icomoon();
      color: $secondary-color;
      font-size: 32px;
      transition: color $default-transition-length;
    }
    &:hover:before {
      color: darken($secondary-color, 4%);
    }
    @include breakpoint(large) {
      padding-left: 50px;
      &:before {
        font-size: 38px;
      }
    }
  }
}

// Audit Overview blocks
body.audits-overview div.region-bottom div.block-views  {
  @include content-width(breakout);
  @include vertical-space(default);
  .block-container {
    @include breakout-container();
  }
  .block-content {
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: 'article', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: 'article', $bottom-gutter: 15, $gutter: 30px);
    }
  }
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
  }
  .breakout-cta {
    //@include button();
    //@include cga-button();
    //@include cta-button();
    //@include breakout-cta();
    @include breakout-cta-all-defaults();
  }
  // smallify the department name
  .views-field-field-department .field-content {
    @include small-header();
    font-size: 11px;
  }
  // No striping in views
  table.views-view-grid {
    width: 100%;
    background-color: transparent !important;
  }
  table tr {
    background: inherit;
    td {
      vertical-align: top;
      width: 33%;
    }
  }
  h3 {
    @include small-label-font-styles();
  }
}

// Popular bills block
#block-cga-bills-popular-bills {
  @include content-width(breakout);
  @include vertical-space(default);
  .block-container {
    @include breakout-container();
  }
  .block-content {
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: 'article', $bottom-gutter: 15, $gutter: 30px);
      .node-bill {
        margin-top: 0;
      }
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: 'article', $bottom-gutter: 15, $gutter: 30px);
    }
  }
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
  }
  .breakout-cta {
    @include breakout-cta-all-defaults();
  }
}

// Bill Sponsors
#block-cga-bills-bill-sponsors {
  @include vertical-space(small);
  .block-title {
    @include block-header();
    margin-bottom: 10px;
  }

  .member {
    @include member-layout();
  }
  .sponsor-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .member-title {
      @include small-label-font-styles();
      display: block;
      line-height: 1;
    }
  }
}

// Bill Committees
#block-cga-blocks-bill-committees {
  @include vertical-space(small);
  .block-title {
    @include block-header();
    margin-bottom: 10px;
  }
}

// Bill Status block
#block-cga-bills-bill-status {
  @include vertical-space(default);
  .block-title {
    @include header-font-size(h2);
    font-family: $sans-font-stack;
  }
}

// Bill Documents block
#block-cga-bills-bill-documents {
  @include vertical-space(default);
  .block-title {
    font-family: $sans-font-stack;
  }
  .block-content h2 {
    font-family: $sans-font-stack;
    margin: 0 0 20px 0;
  }
  select {
    margin: 0 0 30px 0;
  }
  .hearing-item-meta {
    margin-bottom: 5px;
    @include clearfix();
    .field-name-field-committee-rep-doc {
      display: inline;
      div {
        display: inline;
      }
    }
    a {
      font-family: $sans-font-stack;
      font-size: 14px;
      @include icon-inline-attach($icon-document, 14)
    }
    .hearing-summary-link {
      margin-left: 15px;
    }
    @include breakpoint(large) {
      $document-width: 500px;
      .description-wrapper {
        float: left;
        width: calc(100% - #{$document-width});
      }
      .documents-wrapper {
        float: right;
        width: #{$document-width};
        text-align: right;
      }
    }
  }
}

// Bill Related Blocks block
#block-bean-bill-related-blocks {
  @include vertical-space(default);
}

#block-cga-bills-bill-most-recent {
  .recent-item {
    display: inline-block;
    margin: 0 15px 15px 0;
    &:last-child {
      margin-left: 0;
    }
  }
  .block-content {
    a {
      @include default-cta-button(gold);
      margin: 0;
    }
  }
}


// Bill sponsors
#block-cga-bills-bill-sponsors {
  @include vertical-space(medium);
  .block-title {
    @include block-header();
  }
}

// Bill committees
#block-cga-bills-bill-committees {
  .block-title {
    @include block-header();
  }
  .committee-item {
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
}

// Related items on bill detail pages
#block-bean-bills-related-items {
  @include content-width(window);
}

// Bill
#block-cga-bills-bill-schedule-items {
  @include vertical-space();
  .block-title {
    font-family: $sans-font-stack;
  }
  .node-schedule-item.node-teaser {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $base-gray-medium;
    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }
}

// Related bills on bill node
#block-cga-bills-related-bills {
  .block-title {
    @include block-header();
  }
  .related-bill {
    @include default-small-item-label('.bill-number', '.bill-link');
  }
}




/**
 * Agency Blocks
 */
// Agency Roster
.agency-roster {
  .block-title-container {
    text-align: center;
    h2 {
      @include block-header();
    }
  }
  .roster-items {
    @include block-grid($number: 1, $selector: '.roster-item');
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: '.roster-item', $bottom-gutter: 25);
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: '.roster-item', $bottom-gutter: 25);
    }
    .roster-item:last-child {
      margin-bottom: 0;
    }
  }
  .member {
    @include member-layout();
    .member-image {
      .image-ph {
        display: block;
        height: 70px;
        width: 70px;
        background-color: $base-gray-medium;
        border-radius: $global-rounded;
      }
    }
    .member-details {
      .member-title,
      .member-role {
        @include small-label-font-styles();
        line-height: 1;
        display: block;
      }
      .member-role {
        font-size: 16px;
      }
      h4 {
        margin: 0;
        line-height: 1.2;
      }
    }
  }
}


/**
 * Legislator Related Blocks
 */
#block-cga-legislators-legislator-contact {
  @include vertical-space();
  .block-title-container {
    text-align: center;
  }
  .block-title {
    @include block-header();
  }
  .legislator-contact {
    @include breakout-container();
    &.has-email {
      padding-bottom: 50px;
    }
  }
  .contact-email a {
    @include breakout-cta-all-defaults();
  }
  .field,
  .contact-address {
    margin-bottom: 15px;
    @include header-font-size(h5);
    line-height: 1.3;
  }
  .field-name-field-contact-address,
  .field-name-field-address{
    margin-bottom: 0;
  }
  .directions {
    font-size: 16px;
    a {
      font-family: $sans-font-stack;
    }
  }
  .contact-website {
    font-family: $sans-font-stack;
  }
  .field-name-field-contact-address,
  .field-name-field-contact-phone,
  .field-name-field-address{
    .field-label {
      @include small-label-font-styles();
      font-weight: $global-weight-normal;
      text-transform: uppercase;
      line-height: 1;
    }
  }
}

#block-cga-legislators-legislator-district-counties {
  &:not(:last-child) {
    @include vertical-space(medium);
  }
  .block-title {
    @include block-header();
  }
  .district-image {
    img {
      display: block;
      max-width: 350px;
      width: 100%;
    }
  }
  .district {
    padding-top: 10px;
  }
  .field-name-field-counties, .field-name-field-district {
    @include inline-drupal-field-items();
    .field-label {
      font-weight: $global-weight-normal;
      @include small-label-font-styles();
      text-transform: uppercase;
      &:after {
        content: ':';
      }
    }
    .field-item {
      font-family: $sans-font-stack;
    }
  }
}

#block-cga-legislators-committee-assignments {
  .block-title {
    @include header-font-size(h2);
    font-family: $sans-font-stack;
  }
  .block-content {
    .committee-assignment {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include breakpoint(medium) {
      @include block-grid(2, '.committee-assignment');
    }
  }
  .committee-link {
    margin: 0;
  }
  .committee-role {
    @include small-label-font-styles();
    display: block;
    line-height: 1;
    font-size: 16px;
  }
}
#block-views-bills-by-legislator-block {
  @include content-width(breakout);
  @include vertical-space();
  @include drupal-view-sort-align($sort-width: 160px);
  .block-title {
    //@include header-font-size(h2);
    font-family: $sans-font-stack;
  }
  .views-row {
    &:first-child {
      border-top: none;
      padding-top: 0;
    }
  }
  .ajax-progress-throbber {
    position: absolute;
    right: -22px;
    top: 8px;
  }

}

#block-cga-legislators-legistator-bills {
  @include content-width(breakout);
  @include vertical-space();

  .block-container {
    position: relative;
  }

  #cga-legislator-bill-sort-form{
    .field {
      width: 160px;
      padding: 0;
      margin: 0 15px;
      display: inline-block;
      label {
        color: $body-font-color;
      }  
    }
    #session{
        width: 175px;
      }
    #sort-by{
        width: 150px;
    }
    #edit-apply{
      margin: 0;
    }

    
  }

  .block-title {
    @include breakout-container(false, sides);
    font-family: $sans-font-stack;
    @include breakpoint(medium) {
      padding-right: 170px;
    }
  }
  .node-bill {
    margin: 0;
    &:first-child,
    &:nth-child(2) {
      border-top: none;
      padding-top: 0;
    }
    .search-main {
      padding-left: 15px;
      padding-right: 15px;
    }
    .search-aside {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include breakpoint(medium) {
      .search-main {
        padding-left: 0;
      }
      .search-aside {
        padding-right: 0;
      }
    }
  }
  .ajax-progress-throbber {
    position: absolute;
    right: -22px;
    top: 8px;
  }

}



// Legislator search form block
#block-cga-legislators-legislator-search {
  background-image: $image-search-bg;
  padding: 30px 0 40px 0;
  @include bg-cover();
  @include vertical-space(default);
  .block-container {
    @include content-width(narrow);
  }
  label {
    color: $white;
    font-size: 16px;
    margin-bottom: 3px;
  }
}
// Officers on Legislator overview page
#block-cga-legislators-legislator-officers {
  .officers-block {
    //@include vertical-space(medium);
  }
  h3 {
    @include block-header();
  }
  .officer {
    @include media();
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .officer-image {
    @include media-left(middle, 10);
    @include headshot-teaser();
  }
  .officer-info {
    @include media-body(middle);
    .officer-title {
      display: block;
      line-height: 1;
      @include small-label-font-styles();
    }
    .officer-name {
      margin: 0;
      line-height: 1.2;
    }
  }
}
// Legislator overview table search
#block-views-legislator-overview-block {
  .views-exposed-form {
    label {
      font-weight: $global-weight-normal;
      color: $light-font-gray;
      font-size: 16px;
    }
    select {
      font-size: 15px;
      color: $body-font-color;
    }
  }
}

// Legislator overview session switcher
#block-cga-legislators-legislator-overview-by-session {
  @include vertical-space(small);
  @include breakpoint(large) {
    @include vertical-space(medium);
  }
  .block-title {
    @include small-label-font-styles();
    margin: 0;
  }
  select {
    margin: 0;
    width: auto;
  }
}

// Legislator directory block
#block-views-legislator-directory-block {
  .views-exposed-widgets {
    position: relative;
    select {
      margin: 0;
    }
  }
  .ajax-progress-throbber {
    position: absolute;
    left: -22px;
    bottom: 8px;
  }
}

/**
 * Schedule Related Blocks
 */
// Upcoming Schedule Items block
#block-cga-blocks-upcoming-schedule-items {
  @include vertical-space(default);
  .block-title {
    @include header-font-size(h2);
    font-family: $sans-font-stack;
    margin-bottom: 15px;
  }
}


/**
 * Publication blocks
 */
// Related publications views block
#block-views-publications-related {
  @include content-width(breakout);
  @include drupal-view-sort-align();
  .block-title {
    font-family: $sans-font-stack;
  }
  .views-row {
    &:first-child {
      border-top: none;
      padding-top: 0;
    }
  }
  .more-link {
    @include breakout-container(false, sides);
    text-align: left;
    a {
      @include default-cta-button();
    }
  }
}

// Related publications block
#block-cga-publications-related-publications {
  .block-title {
    @include block-header();
  }
  .related-publication {
    @include default-small-item-label('.publication-type', '.publication-link');
  }
}


/**
 * Budget blocks
 */
// Budget view with exposed filters
#block-views-budget-documents-block, .view-budget-documents {
  .block-title {
    @include header-font-size(h3);
    font-family: $sans-font-stack;
  }
  .views-exposed-widgets {
    input[type="text"],
    select {
      margin: 0;
      width: 100%;
    }
    input[type="submit"] {
      margin-top: 0;
    }
    .views-exposed-widget {
    width: 20%;
      padding-top: 0;
      label {
        font-size: 14px;
        line-height: 1;
        margin-bottom: 5px;
      }
      .description {
        padding: 2px 0 0 0;
        line-height: 1.2;
        font-size: 12px;
        font-style: italic;
      }
    }
    .views-widget-filter-field_department_topic_target_id {
      padding: 0 16px 8px 0;
    }
    .views-widget-filter-field_budget_doc_type_target_id {
      padding: 0 16px 8px 0;
    }
    .views-widget-filter-field_date_value {
      width: 100%;
      padding: 0 16px 0 0;
      & > .views-widget {
        & > div {
          width: 50%;
          float: left;
          div {
            margin: 0;
            width: 100%;
          }
          &:first-child {
            padding-right: 5px;
          }
          &:last-child {
            padding-left: 5px;
          }
        }
      }
    }
    .views-submit-button, .views-reset-button {
      width: 10%;
      padding: 0;
    }
    .views-submit-button, .views-reset-button {
      margin-top: 18px;
      position: relative;
      .ajax-progress-throbber {
        position: absolute;
        left: 74px;
        bottom: 10px;
      }
    }
    @include breakpoint(medium) {
    
      .views-widget-filter-field_department_topic_target_id {
        width: 16%;
        padding-bottom: 0;
      }
      .views-widget-filter-field_budget_doc_type_target_id {
        width: 16%;
        padding-bottom: 0;
      }
      .views-widget-filter-field_date_value {
        width: 30%;
        padding-bottom: 0;
      }
      .views-submit-button, .views-reset-button {
        width: 7%;
        padding: 0 15px 0 0;
      }
   
    }
    @include breakpoint(xlarge) {
      .views-widget-filter-field_department_topic_target_id {
        width: 16%;
        padding-bottom: 0;
      }
      .views-widget-filter-field_budget_doc_type_target_id {
        width: 16%;
        padding-bottom: 0;
      }
      .views-widget-filter-field_date_value {
        width: 30%;
        padding-bottom: 0;
      }
      .views-submit-button, .views-reset-button {
        width: 7%;
        padding: 0 15px 0 0;
      }
    }
  }
}


/**
 * Laws blocks
 */
#block-views-exp-laws-overview-block {
  @include breakpoint(large) {
    &:last-child {
      text-align: right;
      label {
        text-align: left;
      }
    }
  }

  .views-exposed-widgets {
    display: inline-block;
    position: relative;
    margin: 0;
  }
  .views-exposed-widget {
    padding: 0;
    float: none;
  }
  select {
    margin: 0;
  }
}
.view-laws-overview {
  .views-field{
    vertical-align: text-top;
  }
  .views-field:not(.views-field-title){
    width: 1.1in;
  }
}

#block-bean-chamber-publications-block, #block-cga-sessions-session-documents {
  padding-left: 15px;
  padding-right: 15px;
  .block-container {
    border: 2px solid $base-gray-light;
    .block-title {
       text-align: center;
       // @include block-header();
       margin-bottom: 25px;
    }
    .block-title-container{
       padding-top: 35px;
       text-align: center;
    }

    .session-documents {
       @include content-width(breakout);
       @include vertical-space(default);
       @include block-grid($number: 2, $selector: '.document-container');
       @include breakpoint(medium) {
         @include block-grid($number: 2, $selector: '.document-container');
     }

     .document-container{
      .document-section-title{
         font-family: "canada-type-gibson",Helvetica,Arial,sans-serif;
          color: #808080;
          font-weight: normal;
          font-size: 15px;
          text-transform: none;
       }
       width: 50%;
       padding-bottom: 35px;
       .document-section-title{
          width: 100%;
        }
      }
      #status-sheets{
          float: left;
          padding-left: 4.5em;
      }
      #journals {
          float: right;
          padding-left: 3em;
      }
    }
  }
}

#block-cga-blocks-house-journal-text-link, #block-cga-blocks-senate-journal-text-link, #block-cga-blocks-house-status-sheets, #block-cga-blocks-senate-status-sheets{
  .block-title{
    @include header-font-size(h3);
   @include title-space(section-title);
   font-family: $sans-font-stack;
  }
  padding-bottom: 80px;
}
.clearfix.notification-bar-message.notification-bar-status {
      .content-left{
        text-align: center;
        margin-left: 10%;
        background-color: grey;
        font-family: $sans-font-stack !important;
        width: 200px;
      }
      color: white;
      background-color: #004376;
      .content{
        font-family: $sans-font-stack !important;
        width: 100%;
        text-align: center;
        p{
          font-size:  13px;
          margin-bottom: 0;
        }
        a{
          color: rgb(115, 156, 221);
        }
      }
      .content-right{
        float: right;
        color: #004376;
        margin: 0px;
        width: 0px;
        border: 1px solid #004376;
        border-radius: 100%;
        background-color: #004376;
        height: 0px;
      }
}
