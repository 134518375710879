/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Original "Base" styles
 *
 * * * * * * * * * * * * * * * * * * * * */



//
// Messages
//

$errorbg: #d3113a;
$warningbg: #d28712;
$statusokbg: #2ca20d;
//$message-icons: sprite-map("messages/*.png");

//
// Colors - Tabs
//

$color-tabs-wrap-background: #fafafa;
$color-tabs-wrap-border: #efefef;
$color-tabs-link: #251923;
$color-tabs-link-background: #efefef;
$color-tabs-link-hover: #ffffff;
$color-tabs-link-hover-background: #251923;
$color-tabs-link-alt-1: #b6c4c5;
$color-tabs-link-alt-2: #575642;
$color-tabs-link-alt-3: #4c4650;
$color-tabs-link-alt-4: #251923;
