/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Snap icon styles
 *
 * * * * * * * * * * * * * * * * * * * * */

// Fix for IE
svg {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

// Use for responsive container
.svg-container {
  width: 100%;
  height: 0;
  padding-top: 128%; // targets the aspect ratio of the SVG
  position: relative;
}
.svg-container svg {
  position: absolute;
  top: 0;
  left: 0;
}


// Default snap-icon styles
.snap-icon {
  .icon {
    path,
    polygon,
    circle,
    ellipse,
    rect {
      fill: $secondary-color;
    }
  }
  .icon-bg {
    circle {
      fill: $lightest-gray;
      transition: all $default-transition-length;
    }
  }
}

.snap-cta-icon {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 109.09%;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

// Alternative Promoted Content styles
.paragraphs-item-simple-promoted-item {
  .snap-icon {
    .icon {
      path,
      polygon,
      circle,
      ellipse,
      rect {
        fill: $white;
      }
    }
    .icon-bg {
      circle {
        fill: #467496;
      }
    }
  }
}