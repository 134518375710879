/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Homepage specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */

.node-type-homepage {
  .main-content-section-container .view-mode-full {
    margin-bottom: 0;
  }
}