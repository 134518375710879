/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Global Default Site Styles
 *
 * * * * * * * * * * * * * * * * * * * * */

html,
body,
.page-wrapper {
  height: 100%;
}

.menu-block-wrapper {
  a,
  li {
    font-family: $sans-font-stack;
  }
}
.top-bar-title {
  font-family: $sans-font-stack;
  strong {
    font-weight: normal;
  }
}

li, p, label {
  font-size: 18px;
}

h1 {
  line-height: 1.1;
}


.headshot-crop {
  border-radius: $global-rounded;
  overflow: hidden;
  img {
    display: block;
  }
}

.breakout-section {
  @include content-width(breakout);
  .breakout-row {
    .breakout-item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: '.breakout-item', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: '.breakout-item', $bottom-gutter: 15, $gutter: 30px);
    }
    .breakout-item {

    }
  }
}

.small-label {
  @include small-label-font-styles();
  line-height: 1;
}

.page-qualifying-title {
  display: block;
  font-family: $sans-font-stack;
  font-size: 18px;
  text-transform: uppercase;
  color: $light-font-gray;
  line-height: 1;
  @include breakpoint(medium) {
    margin-bottom: -5px;
    font-size: 20px;
  }
}

// hide things before foundation is initialized
.f-not-processed {
  .menu-block-1,
  .menu-block-2 {
    display: none;
  }
}

.no-js.f-not-processed {
  .menu-block-1,
  .menu-block-2 {
    display: block;
  }
}
.disabled{
  pointer-events: none;
  cursor: default;
  opacity: .5;
  }
