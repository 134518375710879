////
/// Slick custom stypes
////

// Global slick styles
.slick-next,
.slick-prev {
  z-index: z(above-content);
  height: 30px;
  width: 30px;
  &:before {
    @include icomoon();
    font-size: 28px;
    line-height: 30px;
  }
}
.slick-next {
  right: 0;
  &:before {
    //content: $icon-circle-arrow-right;
  }
}
.slick-prev {
  left: 0;
  &:before {
    //content: $icon-circle-arrow-left;
  }
}
.slick-dots {
  margin: 0;
  bottom: 10px;
  li {
    button:before {
      content: '';
      display: block;
      font-size: 14px;
      background-color: $white;
      height: 10px;
      width: 10px;
      margin: 5px;
      border-radius: 100px;
    }
    button:focus:before {
      opacity: 1;
    }
    &.slick-active {
      button:before {
        color: $white;
      }
    }
  }
}

// Promoted item slick slider styles
.slick-slider-promoted {
  .slick-next,
  .slick-prev {
    height: 100%;
    opacity: 0;
    &:focus {
      opacity: 1;
    }
    @include breakpoint(medium) {
      opacity: 0.75;
    }
  }
  @include breakpoint(medium) {
    .slick-next {
      right: 10px;
    }
    .slick-prev {
      left: 10px;
    }
  }
  @include breakpoint(large) {
    .slick-next {
      right: 50px;
    }
    .slick-prev {
      left: 50px;
    }
  }
  @include breakpoint(xlarge) {
    .slick-next {
      right: 150px;
    }
    .slick-prev {
      left: 150px;
    }
  }
}

// Homepage slick slider styles
.slick-slider-main {
  .slick-list {
    height: 400px;
    overflow: visible;
    max-width: 1200px;
    margin: 0 auto;
  }
  .slick-prev {
    left: 20px;
    z-index: z(defined);
  }
  .slick-next {
    right: 20px;
  }
  .slide-content {
    @include bg-cover();
    position: relative;
    z-index: z(slides, default);
    height: 430px;
    padding-top: 30px;
    transform: translateY(-30px);
    transition: transform $main-slider-transition-speed;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: z(slides, box-shadow);
      box-shadow: 0 1px 10px -1px $black;
      opacity: 0;
      transition: opacity $main-slider-transition-speed;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $primary-color;
      opacity: 0.8;
      transition: opacity $main-slider-transition-speed;
      z-index: z(slides, overlay);
    }
    .prev-next-title {
      position: absolute;
      bottom: 0;
      z-index: z(slides, prev-next-text);
      width: 200px;
      padding: 5px;
      line-height: 1.2;
      color: $white;
      font-family: $sans-font-stack;
      opacity: 0.6;
      transition: opacity $main-slider-transition-speed;
      @include breakpoint(xlarge) {
        padding: 10px;
      }
    }
  }
  .content-panel {
    background: url('/sites/all/themes/themekit/images/transparent.png');
    text-shadow: 1px 1px #999999;
    transform: translate3d(0, -50px, 0);
    visibility: hidden;
    transition: opacity 250ms, transform 250ms, visibility 0ms;
    transition-delay: 0ms, 0ms, $main-slider-transition-speed * 1.5;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .previous-slide,
  .next-slide {
    cursor: pointer;
    .slide-content {
      &:hover {
        .prev-next-title {
          opacity: 1;
        }
      }
    }
  }
  .previous-slide .slide-content {
    .prev-next-title {
      right: 0;
      text-align: right;
    }
  }
  .next-slide .slide-content {
    .prev-next-title {
      left: 0;
    }
  }
  .transition-slide {
    cursor: default;
    .slide-content {
      .prev-next-title {
        opacity: 0;
      }
      &:hover {
        .prev-next-title {
          opacity: 0;
        }
      }
    }
  }
  .slick-center .slide-content {
    //height: 430px;
    transform: translateY(0);
    z-index: z(slides, active);
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 0;
    }
    .prev-next-title {
      opacity: 0;
    }
    .content-panel {
      background: url('/sites/all/themes/themekit/images/transparent.png');
      text-shadow: 1px 1px #999999;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      transition-delay: $main-slider-transition-speed, $main-slider-transition-speed, 0ms;
    }
  }
}

// Gallery slick slider styles
.slick-slider-gallery {
  .slick-next,
  .slick-prev {
    height: 100%;
    &:before {
      color: $medium-gray;
    }
  }
  .slick-next {
    right: -20px;
    @include breakpoint(medium) {
      right: -30px;
    }
  }
  .slick-prev {
    left: -20px;
    @include breakpoint(medium) {
      left: -30px;
    }
  }
}
