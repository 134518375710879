/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Tab styles
 *
 * * * * * * * * * * * * * * * * * * * * */

.tabs-holder {

  .select-holder {
    select {
      width: 100%;
      border: 2px solid $border-dark-gray;
      //background-color: $base-gray-light;
    }
    @media #{$large-up} {
      display: none;
    }
  }

  .f-tabs {
    display: none;
    @media #{$large-up} {
      display: block;
    }
  }
  .tabs {
    @include clearfix;
    position: relative;
    margin: 0;
    list-style-type: none;
    border: none;

    @include tab-height(46);

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $base-gray-light;
      @include cga-border(bottom, 2px, $border-dark-gray);
    }
    & > li {
      @include tabs-title();
      float: left;
      position: relative;
      &.is-active {
        padding-top: 0;
        & > a {
          color: $white;
        }
      }
      & > a,
      & > .tab-label {
        display: block;
        padding: 10px 20px;
        line-height: 1;
        font-size: 19px;
        color: $light-font-gray;
        font-family: $sans-font-stack;
        background-color: $base-gray-light;
        position: relative;
        z-index: z(defined);
        &:after {
          content: '';
          position: absolute;
          display: block;
          bottom: 2px;
          right: 0;
          z-index: z(further-below);
          border-right: 1px solid $base-gray-medium;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: z(below);
          width: 100%;
          transition: all 500ms;
          height: 0;
          background: $white;
          left: 0;
          bottom: 0;
          border-top: 2px solid $border-dark-gray;
          border-left: 2px solid $border-dark-gray;
          border-right: 2px solid $border-dark-gray;
        }
        &:hover {
          color: $body-font-color;
          background-color: $base-gray-light;
        }
        &:focus,
        &[aria-selected='true'] {
          background: $base-gray-light;
          color: $body-font-color;
        }
      }
    }
  }
  .tabs-content > .content {
    @include tabs-panel();
    padding-left: 0;
    padding-right: 0;
  }

}
