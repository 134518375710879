/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Styles for taxonomies
 *
 * * * * * * * * * * * * * * * * * * * * */


.field-name-field-subjects {
  .field-item:not(:last-child):after {
    margin-right: 3px;
  }
  .term-link,
  .term-item {
    font-family: $sans-font-stack;
  }
  .term-link:before {
    @include icomoon();
    margin-right: 5px;
  }
  .term-item:before {
    @include icomoon();
    margin-right: 5px;
    color: $primary-color;
  }
}


// All subject taxonomy icons
.taxonomy-link-term-37:before {
  content: $icon-agriculture-tid-37;
}
.taxonomy-link-term-42:before {
  content: $icon-business-dev-tid-42;
}
.taxonomy-link-term-41:before {
  content: $icon-capital-construction-tid-41;
}
.taxonomy-link-term-246:before {
  content: $icon-children-domestic-tid-246;
}
.taxonomy-link-term-46:before {
  content: $icon-civil-law-tid-46;
}
.taxonomy-link-term-47:before {
  content: $icon-judicial-tid-47;
}
.taxonomy-link-term-49:before {
  content: $icon-school-finance-tid-49;
}
.taxonomy-link-term-52:before {
  content: $icon-elections-tid-52;
}
.taxonomy-link-term-40:before {
  content: $icon-energy-tid-40;
}
.taxonomy-link-term-236:before {
  content: $icon-finance-commerce-tid-236;
}
.taxonomy-link-term-65:before {
  content: $icon-fiscal-taxes-tid-65;
}
.taxonomy-link-term-63:before {
  content: $icon-gaming-tid-63;
}
.taxonomy-link-term-2056:before {
  content: $icon-healthcare-tid-56;
}
.taxonomy-link-term-50:before {
  content: $icon-higher-education-tid-50;
}
.taxonomy-link-term-58:before {
  content: $icon-housing-tid-58;
}
.taxonomy-link-term-57:before {
  content: $icon-human-services-tid-57;
}
.taxonomy-link-term-69:before {
  content: $icon-immigration-tid-69;
}
.taxonomy-link-term-44:before {
  content: $icon-insurance-tid-44;
}
.taxonomy-link-term-59:before {
  content: $icon-labor-tid-59;
}
.taxonomy-link-term-2071:before {
  content: $icon-liquor-tobacco-marijuana-tid-62;
}
.taxonomy-link-term-54:before {
  content: $icon-local-government-tid-54;
}
.taxonomy-link-term-55:before {
  content: $icon-military-tid-55;
}
.taxonomy-link-term-38:before {
  content: $icon-natural-resources-tid-38;
}
.taxonomy-link-term-60:before {
  content: $icon-public-heath-tid-60;
}
.taxonomy-link-term-53:before {
  content: $icon-state-government-tid-53;
}
.taxonomy-link-term-66:before {
  content: $icon-revenue-budget-tid-66;
}
.taxonomy-link-term-241:before {
  content: $icon-telecom-it-tid-241;
}
.taxonomy-link-term-67:before {
  content: $icon-transportation-tid-67;
}
.taxonomy-link-term-39:before {
  content: $icon-water-tid-39;
}
.taxonomy-link-term-2066:before {
  content: $icon-crimes-tid-45;
}
.taxonomy-link-term-57601:before {
  content: $icon-capitol;
  font-size: 60px !important;
}
.taxonomy-link-term-57606:before {
  content: $icon-person;
  font-size: 60px !important;
}
.taxonomy-link-term-57611:before {
  content: $icon-coins;
  font-size: 50px !important;
}
