/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Agency & Agency Detail pages
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Agency & Agency Detail pages
 */
.node-type-minisite-page {
  // Layout
  .region-content-top {
    @include vertical-space(medium);
  }
  // Style
  .minisite-page-header-bg {
    @include bg-cover();
    height: 400px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .minisite-page-header-content {
      position: relative;
      max-width: 1000px;
      margin: 0 auto;
      height: 400px;
      padding: 0 15px;
    }
    .minisite-name {
      @include v-align-transform();
      width: 100%;
      padding: 0 15px;
      margin: 0;
      text-align: center;
      color: $white;
    }
  }
  main#main-content{
    width: 100%;
  }
  div.paragraphs-item-fancy-staff-directory {
     div.field-name-field-profile-picture {
        @include circle(180px, #fff, block);
        img {
          margin-right: 1em;
        }
        margin-bottom: 1em;
     }
     div.field-name-field-biography {
       .field-items .field-item { 
         & > p:first-child {
           padding-left: 160px;
         }
        }
     }
   }
}

.node-type-minisite-page {
  // Layout
  .minisite-top-container {
    @include content-width();
    @include breakpoint(large) {
      @include vertical-space();
    }
  }
  .minisite-top {
    @include breakpoint(large) {
      @include vertical-space(default);
    }
    .top-main,
    .top-aside {
      @include vertical-space(medium);
    }
    @include breakpoint(large) {
      @include grid-row($behavior: nest);
      .top-main {
        @include grid-column(8);
        margin-bottom: 0;
      }
      .top-aside {
        @include grid-column(4);
        margin-bottom: 0;
      }
    }
    .full-width{
      width: 100%;
    }
  }
  .minisite-contact {
    @include breakout-container();
    .minisite-contact-content {
      @include breakpoint(medium only) {
        @include block-grid($number: 3, $selector: '.minisite-contact-col', $bottom-gutter: 0, $gutter: 30px);
        .field {
          margin-top: 0;
        }
      }
    }
    .block-title-container {
      text-align: center;
    }
    .block-title {
      @include block-header();
    }
    .field-name-field-minisite-phone,
    .field-name-field-capitol-phone,
    .field-name-field-fax,
    .field-name-fiel-address,
    .field-name-field-contact-fax,
    .field-name-field-address{
      .field-item {
        @include header-font-size(h5);
      }
    }

    .field-name-field-contact-phone,
    .field-name-field-phone,
    .field-name-field-contact-fax {
      margin-top: 15px;
    }
    .field-name-field-address,
    .field-name-field-minisite-phone,
    .field-name-field-capitol-phone,
    .field-name-field-fax {
      .field-label {
        @include small-header();
        line-height: 1.1;
        font-weight: $global-weight-normal;
      }
      .field-items {
        line-height: 1.2;
      }
    }
    .email-breakout-cta {
      @include breakout-cta-all-defaults();
    }
    &.has-email {
      padding-bottom: 50px;
    }
  }
  .paragraphs-items-field-p-promoted-content {
    @include vertical-space();
  }
  .paragraphs-items-field-p-content-row {
    @include content-width(breakout);
    @include vertical-space();
  }
  .session-schedule-teaser {
    h3 {
      @include schedule-header(capitalize);
    }
    .full-schedule-link {
      @include button();
      @include cga-button();
      @include cta-button($icon-calendar, $white, before);
      &:after {
        font-size: 16px;
      }
    }
  }
  .todays-proceedings {
    @include content-width();
    @include vertical-space();
  }
  .paragraphs-item-component-secondary-body {
    @include vertical-space();
  }
  .breakout-section {
    &:not(:last-child) {
      @include vertical-space();
    }
    .breakout-container {
      @include breakout-container();
    }
    .breakout-item {
      h4 {
        @include block-header();
      }
    }
  }
  // Style
  .agency-top-container .node-title {
    font-family: $sans-font-stack;
  }
  .agency-top-container .field-name-field-p-2-dynamic-links {
    margin-bottom: 30px;
    .field-items {
      text-align: right;
    }
    .field-item {
      display: inline-block;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      font-family: $sans-font-stack;
      text-decoration: underline;
      font-size: 15px;
    }
  }
  .field-name-field-head-section-title {
    .field-item {
      @include header-font-size(h3);
      font-family: $sans-font-stack;
      margin-bottom: 10px;
    }
  }
  .field-name-field-related-publications {
    .field-item {
      @include default-small-item-label('.publication-type', 'a');
    }
  }
  .breakout-section {
    .view-audits .views-row {
      padding-bottom: 1em;
      .field-content {
        a {
          font-size: 22px;
          font-size: 1.375rem;
        }
      }
    }
    a.breakout-cta {
      @include breakout-cta-all-defaults();
    }
  }
  .chamber_aside_cta_bar{
    .cta-button{
     font-size: 0.8rem; 
    }
  }
  .breakout-section{
    .breakout-row{
      margin: 40px 0;
    }
  }
  .field-name-field-bottom > .field-items > .field-item {
    // Set defaults for ALL paragraphs
    & > .entity-paragraphs-item {
      @include content-width();
      @include vertical-space();
    }
    // Override the paragraphs with special margin needs
    & > .paragrahps-item-component-promoted {
      @include content-width(window);
    }
    & > .paragraphs-item-layout-three-col,
    & > .paragraphs-item-component-link-bar {
      @include content-width(breakout);
    }
  }
}


.node-type-minisite-page {
  .minisite-name {
    @include header-font-size(h1);
  }
  .node-title {
    @include header-font-size(h2);
    //font-family: $sans-font-stack;
  }
  .main-content-section-container {
    .main-content-section {
      @include content-width();
      @include vertical-space();
      max-width: 100%;
    }
  }
  .field-name-field-bottom > .field-items > .field-item {
    // Set defaults for ALL paragraphs
    & > .entity-paragraphs-item {
      @include content-width();
      @include vertical-space();
    }
    // Override the paragraphs with special margin needs
    & > .paragrahps-item-component-promoted {
      @include content-width(window);
    }
    & > .paragraphs-item-layout-three-col,
    & > .paragraphs-item-component-link-bar {
      @include content-width(breakout);
    }
  }
  .field-name-field-staff-member-item {
    .field-name-field-biography {
      padding-bottom: 3em;
    }
    .field-name-field-profile-picture img {
      float: left;
      //padding-right: 1em;
      //border-radius: $global-rounded;
    }
    .field-name-field-full-name {
      font-weight: bold;
      font-size: 2.2rem;
    }
    .field-name-field-title {
      font-style: italic;
      font-size: 1.6rem;
      padding-bottom: 1em;
   }
  }
  .field-type-faqfield{
    .field-label{
      color:  white;
    }
  }
}
