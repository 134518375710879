/**
 * @file
 * All sitewide layout styles
 */



/**
 * Fullwidth layouts
 */
.node-type-agency,
.node-type-agency-detail
.node-type-minisite-page,
.node-type-html-document,
.node-type-homepage {
  @include fullwidth-layout();
}

// Temporary layout structure for prototyping
.page-wrapper {
}
#page {
}

#content-btth {
  @include grid-column-row();
}

.region-bottom {
  > .block {
    @include content-width();
  }
}

// Some reusable classes
.row-nest {
  @include grid-row($behavior:nest);
}
.one-third {
  @include grid-column(12);
  @include breakpoint(large) {
    @include grid-column(4);
  }
}
.two-thirds {
  @include grid-column(12);
  @include breakpoint(large) {
    @include grid-column(8);
  }
}
.one-half {
  @include grid-column(12);
  @include breakpoint(large) {
    @include grid-column(6);
  }
}


// Main Content
.main-content-section-container {
  @include content-width();
  @include vertical-space($position: top);
  .view-mode-full:not(:last-child) {
    @include vertical-space(medium);
  }
}
// Exception content types here
.node-type-agency,
.node-type-agency-detail,
.node-type-minisite-page{
  .main-content-section-container {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}
.full-width {
  .main-content-section {
    @include grid-row($behavior: nest);
    @include breakpoint(large) {
      @include vertical-space(default);
    }
    > .main-content {
      @include grid-column (12);
      @include vertical-space(medium);
    }
  }
}


.main-content-section {
  position: relative;
  @include grid-row($behavior: nest);
  @include breakpoint(large) {
    @include vertical-space(default);
  }
  > .main-content {
    @include grid-column(12);
    @include vertical-space(medium);
    @include breakpoint(large) {
      @include grid-column(8);
      margin-bottom: 0;
    }
  }
  > .aside {
    @include grid-column(12);
    @include vertical-space(medium);
    @include breakpoint(large) {
      @include grid-column(4);
      margin-bottom: 0;
    }
  }
}


// Default media object classes
.media-object {
  @include media();

  .media-body {
    @include media-body($alignment:middle);
  }
  .media-left {
    @include media-left($alignment:middle)
  }
  .media-right {
    @include media-right($alignment:middle)
  }
}

.use-sans-serif{
   font-family: $sans-font-stack !important;
}
