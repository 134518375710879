/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Tables
 *
 * * * * * * * * * * * * * * * * * * * * */

@include breakpoint(xlarge) {
  table#legislators-overview-table {
    width: 70em;
  }
}

table {
  width: 100%;

  th,
  thead th {
    font-family: $sans-font-stack;
    color: $light-font-gray;
    font-size: 14px;
    font-weight: $global-weight-normal;
    text-transform: uppercase;
    border-bottom: none;
    line-height: 1.2;
  }
  td {
    line-height: 1.2;
    font-size: 16px;
    font-family: $sans-font-stack;
    color: $medium-font-gray;
    div,
    p {
      font-size: 16px;
      font-family: $sans-font-stack;
      color: $medium-font-gray;
    }
  }
  tr,
  tr.even,
  tr.odd {
    border: none;
    padding: 0;
  }
  tr.even {
    background-color: transparent;
  }
  tr.odd {
    background-color: whitesmoke;
  }
}

.view-relations-admin {
  table > tbody > tr {
    transition: background-color 300ms;
    &:hover {
      background-color: #e4e4e4;
    }
  }
  .views-field-views-bulk-operations > .form-type-checkbox > .icheck-item {
    top: -8px;
  }
}



// Styles for display: table
.table {
  @include table-style();
}
.table-row {
  @include table-row-style();
}
.table-header {
  @include table-header-style();
}
.table-body {
  @include table-body-style();
}
.table-cell {
  @include table-cell-style();
}


// Pseudo table for responsive stacking/labeling behavior
@include breakpoint(small down) {
  // responsive tables
  @include simple-responsive-table();
  // extra styles
  .responsive-table {
    td {
      padding: 5px 10px 6px 10px;
      font-size: 16px;
      &:first-child {
        padding-top: 10px;
      }
      &:last-child {
        padding-bottom: 11px;
      }
      &:before {
        //@include small-label-font-styles(uppercase);
      }
      div,
      p {
        font-size: 16px;
      }
    }
  }
}
