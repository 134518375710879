/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Publication content type specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */


.node-publication {
  // Because the plural label is created in code and 
  // e3's wonderful commas are done is CSS. GREAT WORK GUYS
  .field-name-field-agency .field-label {
    display: none !important;
  }
  &.view-mode-full {
    .publication-subjects,
    .field-name-field-publication-author,
    .field-name-field-agency,
    .field-name-field-date {
      margin-bottom: 15px;
      .field-label {
        @include small-label-font-styles(uppercase);
        display: inline;
        &:after {
          content: ':';
        }
      }
      .field-items {
        display: inline;
        div {
          display: inline;
        }
      }
    }
    .field-name-field-subjects,
    .field-name-field-agency,
    .field-name-field-author {
      @include inline-drupal-field-items();
    }
    .field-name-field-file, 
    .field-name-field-file-non-searchable {
      a {
        @include default-cta-button(gold);
      }
    }
  }


  &.view-mode-search {
    .field-name-field-agency,
    .field-name-field-date,
    .field-name-field-subjects {
      display: inline;
      div {
        display: inline;
      }
    }
    .field-name-field-subjects,
    .field-name-field-agency {
      @include inline-drupal-field-items();
    }
  }

  &.view-mode-teaser_short {
    .field-name-field-publication-type .field-item {
      @include small-label-font-styles();
    }
  }
}


