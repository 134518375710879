/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * initiative specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */

/**
 * Do not stretch the calendar date picker in webforms
 */
.webform-calendar.hasDatepicker {
  width: inherit;
}

/**
 * border and padding for book-tour webform
 */
#webform-client-form-13056 .webform-component-fieldset {
  border: 1px solid #eaeae9;
  padding: 1em;
}

/**
  * Styling for OSA form
  */
.node-1112331 {
  header {
  & > h1.node-title {
      display: none;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * *
 * 1191611 is the dev form for OSA tax expenditure
 * 1184546 is the prod form for OSA tax expenditure
 * Both forms are at ~/content/stakeholder-input-tax-expenditure
 * * * * * * * * * * * * * * * * * * * * */

#webform-client-form-1112331,
#webform-client-form-962187,
#webform-client-form-1191611,
#webform-client-form-1184546 {
  .webform-component-file {
   & > label {
      display: none;
   }
   & > .description {
      display: none;
   }
  }
  fieldset.webform-component-fieldset {
    padding-bottom: 1em;
    span.fieldset-legend {
      font-size: 1.6em;
      color: #4a4a4a;
      margin-bottom: 2.5em;
    } 
  }
  .form-type-radio {
    padding-left: 10px !important;
  }
}

/**
 * For disabled icheck radio elements
 */
input.noicheck {
  width: 1em !important;
  margin: .8rem 0 1rem;
  & + label {
    display: inline;
    line-height: 1.2;
    color: #000;
    font-size: 18px;
    font-weight: normal;
  }
}

/**
 * Visit and Learn calendar header
 */
body.node-type-webform.page-node-13056 #ui-datepicker-div .ui-datepicker-header {
  color: white;
}


