/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * initiative specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Initiative views block
 */
.form-item-field-initiative-session-tid .description,
.form-item-field-initiative-sessions-tid .description {
  display: none; /* views bug fix */
}

 nav#block-menu-menu-initiatives-menu > ul.sidebar-menu > li.first a {
 background-color: #004376;
 color: #fff;
}

nav#block-menu-menu-initiatives-menu > ul.sidebar-menu > li.menu__item > a.active {
background-color: #1e73b4;
color: #fff;
}

nav#block-menu-menu-initiatives-menu > ul.sidebar-menu > li.menu__item > ul.sidebar-menu > li.menu__item > a.active {
background-color: #1e73b4;
color: #fff;
}

 nav#block-menu-menu-initiatives-menu > ul.sidebar-menu > li.first a.active {
 background-color: #004376;
 color: #fff;
}


nav#block-menu-menu-initiatives-menu ul.sidebar-menu {
  ul.sidebar-menu::before  {
    content: "    ";
  }
  ul.sidebar-menu li a {
    background-color: #eaeae9;
  }
}

.node-initiative {
  .field-type-file {
    span.file {
      a {
        @include button();
        //@include cga-button();
        @include default-cta-button(gold);
      }
    }
  }
  .text-blurb {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  &.view-mode-ballot {
    .field-name-field-ballot-title {
      font-size: 2.2rem;
      margin-bottom: 1em;
    }
    .field-name-field-ballot-subtitle {
      //font-size: 1.2rem;
      @include block-header-font-styles();
    }
    .field-name-field-ballot-files {
      a {
        @include default-cta-button();
      }
    }
  }
}
