/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All header styles
 *
 * * * * * * * * * * * * * * * * * * * * */

@media #{$large-up} {
  .parent-link {
    display: none;
  }
}