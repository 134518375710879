/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Audit specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Audit views block
 */
.form-item-field-report-type-tid .description,
.form-item-field-department-tid .description,
body.page-audits div.description {
  display: none; /* views bug fix */
}

#block-views-exp-audit-search-audit-simple {
  .block-container {
    border: 0;
  }
}
.node-type-audit {
  #related_downloads {
    padding: 1em;
    background: whitesmoke;
    h5 {
      font-weight: bold;
    }
  }
  article header {
    margin-bottom: 1.3em;
  }

}

//.download-report-breakout-cta {
//  @include breakout-cta-all-defaults();
//}

/** 
 * Audit Department Taxonomy Page
 */
.section-audit-report-department article {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid $base-gray-medium;
}

/**
 * Audit detail pages
 */
.node-type-audit {
  .header-wrapper {
    //@include vertical-space(default);
  }
  .main-content-section {
    @include vertical-space(medium);
  }
  .node-audit {
    @include vertical-space(small);
    .node-title {
      line-height: 1.2;
      margin-bottom: 10px;
    }
    .bill-subjects-label,
    .bill-session-label {
      @include small-label-font-styles(uppercase);
    }
    .bill-subjects,
    .bill-session {
      margin-bottom: 25px;
    }
    .field-name-field-sessions,
    .field-name-field-subjects {
      @include inline-drupal-field-items();
      a {
        font-family: $sans-font-stack;
        font-size: 15px;
      }
    }
    span.audit-label {
      float: left;
      padding-right: 1em;
      font-weight: bold;
    }
  }
}
/**
 * Turn off striping on audits view
 */
div.view-audits-by-department-taxonomy table tr {
  background: inherit;
}
.node-audit {
  .audit-report-type .field-items .field-item {
    text-transform: capitalize;
  }
  &.view-mode-full {
    .publication-subjects,
    .field-name-field-publication-author,
    .field-name-field-agency,
    .field-name-field-release-date {
      .field-label {
        @include small-label-font-styles(uppercase);
        display: inline;
        &:after {
          content: ':';
        }
      }
      .field-items {
        display: inline;
        div {
          display: inline;
        }
      }
    }
    .field-name-field-department,
    .field-name-field-subjects,
    .field-name-field-author {
      @include inline-drupal-field-items();
    }
    .field-name-field-report-file, .field-name-field-highlight-sheet {
      a {
        @include default-cta-button();
      }
    }
    .audit_files {
      padding-top: 15px;
      span {
        padding-right: 10px;
        display: inline-block;
      }
    }

  }


  &.view-mode-search {
    //.field-name-field-date,
    //.field-name-field-department,
    //.field-name-field-report-number,
    //.field-name-field-release-date,
    //.field-name-field-subjects {
    //  @include inline-drupal-field-items();
    //}
    .field-name-field-department,
    .field-name-field-report-number,
    .field-name-field-release-date,
    .audit-report-number,
    .audit-release-date,
    .audit-department {
      label {
        line-height: 1.3;
        @include small-label-font-styles();
        display: inline;
        text-transform: uppercase;
      }
      span {
        font-family: $sans-font-stack;
      }
      .field {
        @include inline-drupal-field-items();
        font-family: $sans-font-stack;
      }
    }
  }

  &.view-mode-teaser_short {
    .field-name-field-publication-type .field-item {
      @include small-label-font-styles();
    }
  }
}

.audit-for-department-view #cga-audits-audits-for-department-sort-form{
  width: 30%;
}
