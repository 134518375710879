/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Legislator styles
 *
 * * * * * * * * * * * * * * * * * * * * */

// Legislator nodes
.node-type-legislator {
  .region-content-below {
    @include vertical-space(medium, top);
  }

  .node-legislator.view-mode-full {
    .node-title {
      //@include icon-inline-attach($icon-democrat, 24, after);
      @include title-space(main-title);
      &:after {
        @include icomoon();
        margin-left: 10px;
        font-size: 0.9em;
      }
    }
    .affiliation-democrat .node-title:after {
      content: $icon-democrat;
      color: $democrat-color;
    }
    .affiliation-republican .node-title:after {
      content: $icon-republican;
      color: $republican-color;
    }
    .legislator-content {
      font-family: $sans-font-stack;
    }
    .field-name-field-occupation,
    .field-name-field-leadership-position,
    .field-name-field-political-affiliation {
      margin-bottom: 15px;
      .field-label {
        @include small-label-font-styles();
        display: inline;
        text-transform: uppercase;
        &:after {
          content: ':';
        }
      }
      .field-items {
        display: inline;
        div {
          display: inline;
        }
      }
    }
    .field-name-field-legislator-membership {
      margin-bottom: 15px;
    }
    .field-name-field-legislator-membership {
      .field-label {
        @include small-label-font-styles();
        text-transform: uppercase;
        &:after {
          content: ':';
        }
      }
    }
    .legislator-body {
      $small-profile-img-width: 80px;
      $medium-profile-img-width: 160px;

      position: relative;
      @include clearfix();
      .legislator-profile-picture {
        width: $small-profile-img-width;
        float: left;
        @include breakpoint(medium) {
          width: $medium-profile-img-width;
        }
        .field-name-field-profile-picture {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 100%;
          overflow: hidden;
          border-radius: $global-rounded;
          img {
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
          }
        }
      }
      .legislator-content {
        width: calc(100% - #{$small-profile-img-width});
        float: left;
        padding-left: 20px;
        @include breakpoint(medium) {
          width: calc(100% - #{$medium-profile-img-width});
        }
      }
    }
    .legislator-description {
      margin-top: 15px;
    }
  }

}

.node-legislator.view-mode-search {
  .member {
    @include member-layout();
    .member-title {
      @include small-label-font-styles(uppercase);
      display: block;
      line-height: 1;
    }
  }

  .field-name-field-political-affiliation,
  .field-name-field-district {
    display: inline;
    div {
      display: inline;
    }
  }

  .field-name-field-counties {
    @include inline-drupal-field-items();
  }

  .field-name-body {
    margin-top: 15px;
  }
}