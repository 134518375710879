/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Some very basic styles for no javascript enabled.
 * We aren't worrying about trying to make everything work, just some simple hides,
 * etc, to remove some broken looking items
 *
 * * * * * * * * * * * * * * * * * * * * */


.no-js-message {
  background-color: $secondary-color;
  color: $white;
  padding: 15px 0;
  p {
    @include content-width(narrow);
    margin-bottom: 5px;
    line-height: 1.3;
  }
}


.no-js {
  // Make sure menus aren't hidden is js is turned off
  .f-not-processed {
    .menu-block-1,
    .menu-block-2 {
      display: block;
    }
  }

  // Make the homepage slider not look broken
  .paragraphs-item-component-main-slider .slick-slider {
    opacity: 1;
    .slide-content {
      &:after {
        display: none;
      }
      .content-panel {
        opacity: 1;
        visibility: visible;
      }
      .prev-next-title {
        display: none;
      }
    }
  }

  // hide snap icons, since they of course will not work
  .snap-icon-container {
    display: none;
  }

  // Fix main menu to work reasonably
  .menu-block-wrapper {
    .is-expanded {
      position: relative;
    }
    .dropdown {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 200px;
      margin-left: -100px;
      z-index: z(dropdowns, default);
      background: $primary-color;
      a {
        font-size: 14px;
        line-height: 1.3;
        color: $white;
        display: block;
        text-align: center;
        padding: 10px 15px;
        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }
    }
    .menu__item:hover {
      .dropdown {
        display: block;
      }
    }
  }
}

