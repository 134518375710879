/**
 * @file
 * All styles added by themekit
 */

@import "base/z-index";

@import "base/base";
@import "base/normalize";
@import "base/font";
@import "base/animation";
@import "base/app";
@import "base/mixins/mixins-default-drupal";
@import "base/mixins/mixins-general";
@import "base/mixins/mixins-foundation";
@import "base/mixins/mixins-cga";
@import "base/utility-functions";
@import "base/default-drupal";

@import "simple-responsive-tables";

@import "components/global";
@import "components/books";
@import "components/accessibility";
@import "components/header";
@import "components/functional-elements";
@import "components/menus";
@import "components/sidebar-menu";
@import "components/footer";
@import "components/buttons";
@import "components/forms";
@import "components/tables";
@import "components/accordions";
@import "components/blocks";
@import "components/taxonomies";
@import "components/search";
@import "components/navigation";
@import "components/svg";
@import "components/views";
// Add photoswipe without using gulp includePaths since `main.scss` is not descriptive
@import "../bower_components/photoswipe/src/css/main.scss";
@import "../bower_components/photoswipe/src/css/default-skin/default-skin.scss";
@import "components/photoswipe-custom";
@import "slick.scss";
@import "slick-theme.scss";
@import "components/slick-custom";
@import "components/select2";
@import "components/paragraphs";
@import "components/session-schedule";
@import "components/timeline";
@import "components/tabs";
@import "components/jquery-ui";
@import "components/iframe";

@import "content-types/advanced-page";
@import "content-types/agency";
@import "content-types/minisite";
@import "content-types/audit";
@import "content-types/committee";
@import "content-types/committee_detail";
@import "content-types/ballot";
@import "content-types/bill";
@import "content-types/event";
@import "content-types/initiative";
@import "content-types/legislator";
@import "content-types/publication";
@import "content-types/schedule-item";
@import "content-types/html-document";
@import "content-types/homepage";
@import "content-types/webforms";

@import "base/layout";

@import "components/ie";
@import "components/no-js";

