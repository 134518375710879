/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * HTML Document styles
 *
 * * * * * * * * * * * * * * * * * * * * */

.node-type-html-document {
  font {
    font-family: $body-font-family;
  }
  h1.node-title {
    display: none;
    }
}
