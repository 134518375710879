/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All header styles
 *
 * * * * * * * * * * * * * * * * * * * * */


.header-wrapper {
  padding: 5px 15px;
  border-bottom: 4px solid $primary-color;
  @include breakpoint(medium) {
    padding: 15px;
  }
  @include breakpoint(xlarge) {
    padding: 20px 0 0 0;
  }
  header {
    //@include grid-column-row();
    @include content-width(menu);
  }
  .header-top {
    @include breakpoint(xlarge) {
      @include grid-row();
      .brand {
        @include grid-column(6);
      }
      .utility {
        @include grid-column(6);
      }
    }
  }
}

.header-search {
  margin-top: 10px;
  width: 330px;
  float: right;
  input {
    margin: 0;
  }
  .views-exposed-widgets {
    margin: 0;
  }
  .views-widget-filter-search_api_views_fulltext {
    padding-top: 0;
    width: calc(100% - 85px);
    input {
      height: 30px;
      font-size: 14px;
    }
  }
  .views-submit-button {
    @include button();
    @include icon-inline-attach($icon-search, 14);
    width: 85px;
    padding: 0;
    margin: 0;
    &:before {
      left: 10px;
    }
    .form-submit {
      margin: 0;
      padding: 7px 12px 7px 30px;
      font-size: 14px;
      background: transparent;
      border: none;
    }
  }
}

.menu-search {
  .header-search {
    float: none;
    width: 100%;
    margin: 0;
    padding: 15px;
    background: $white;
  }
}

.site-title-area {
  @include media();
  .site-logo {
    @include media-left($padding: 10);
    padding-top: 5px;
    padding-bottom: 5px;
    img {
      width: 50px;
      display: block;
    }
    @include breakpoint(medium) {
      padding-right: 20px;
      img {
        width: 80px;
      }
    }
  }
  .site-title {
    @include media-body(middle);
    line-height: 1.2;
    padding-right: 50px;
    @include breakpoint(medium) {
      padding-right: 0;
    }
  }
  #site-name {
    font-size: 20px;
    line-height: 0.9;
    @include breakpoint(medium) {
      font-size: 34px;
    }
    a {
      color: $body-font-color;
    }
  }
  .session-info {
    display: none;
    margin: 0 0 4px 0;
    font-size: 16px;
    color: $primary-color;
    line-height: 1;
    @include breakpoint(medium) {
      display: block;
    }
  }
}

.menu-block-wrapper {
  margin: 0;
  ul {
    margin: 0;
    list-style-type: none;
  }
  li a {
    padding: 12px 16px;
  }
  &.menu.dropdown {
    .is-dropdown-submenu {
      z-index: z(dropdowns, default);
      a {
        display: block;
      }
    }
  }
}

.is-drilldown {
  width: 100% !important;
  height: auto !important;
}

.top-bar {
  @include top-bar-container();
  @include foundation-title-bar();
}


/**
 * Mobile styles for header
 */
.header-wrapper {
  .utility,
  #main-menu {
    display: none;
  }
  @include breakpoint(xlarge) {
    .utility,
    #main-menu {
      display: block;
    }
    .mobile-main-menu-toggle {
      display: none;
    }
  }
  .brand {
    position: relative;
  }
  .mobile-main-menu-toggle {
    position: absolute;
    top: 5px;
    right: 0;
    height: 40px;
    width: 40px;
    padding: 5px;
    text-indent: -2000px;
    overflow: hidden;
    @include breakpoint(medium) {
      right: 0;
      height: 60px;
      width: 60px;
      padding: 15px;
    }
    .animated-toggle {
      position: absolute;
      top: 5px;
      left: 5px;
      @include breakpoint(medium) {
        top: 15px;
        left: 15px;
      }
    }
  }
}

