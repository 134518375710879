//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Close Button
//  15. Drilldown
//  16. Dropdown
//  17. Dropdown Menu
//  18. Flex Video
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Off-canvas
//  24. Orbit
//  25. Pagination
//  26. Progress Bar
//  27. Reveal
//  28. Slider
//  29. Switch
//  30. Table
//  31. Tabs
//  32. Thumbnail
//  33. Title Bar
//  34. Tooltip
//  35. Top Bar

@import 'util/util';

// 1. Global
// ---------

$blue: #004376;
$light-blue: #004f8c;
$gold: #d28500;
$light-gold: #edb008;
$red: #9a2828;
$light-red: #b73f3f;
$green: #6ba166;
$light-green: #88bb84;
$base-gray: #282924;
$base-gray-medium: #dddddd;
$base-gray-light: #eaeae9;
$base-gray-lightest: #f6f6f6;
$menu-gray-medium: #4a4a4a;
$menu-gray-light: #5d5b5c;
$font-gray: $base-gray;
$medium-font-gray: #4a4a4a;
$light-font-gray: #808080;
$lightest-font-gray: #c0c0c0;
$border-dark-gray: #808080;
$facebook-blue: #3B5998;
$twitter-blue: #55acee;
$senate-color: $red;
$house-color: $green;
$republican-color: #B73F3F;
$democrat-color: #004F8C;

$serif-font-stack: 'freight-text-pro', 'Times New Roman', Georgia, serif;
$sans-font-stack: 'canada-type-gibson', Helvetica, Arial, sans-serif;

$global-font-size: 100%;
$global-width: rem-calc(1130);
$global-lineheight: 1.5;
$primary-color: $blue;
$primary-color-lighter: $light-blue;
$secondary-color: $gold;
$secondary-color-lighter: $light-gold;
$success-color: $green;
$warning-color: $gold;
$alert-color: $red;
$lightest-gray: #f6f6f6;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #000;
$white: #fff;
$body-background: $white;
$body-font-color: $font-gray;
$body-font-family: $serif-font-stack;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-rounded: 1000px;
$global-text-direction: ltr;

$color-block-bg: #ffc87e;
$color-paragraph-layout-bg: #c7e27c;
$color-paragraph-item-bg: #89c3dd;
$color-senate: $green;
$color-house: $red;

$form-border-color: $base-gray-medium;
$form-border-focus-color: $border-dark-gray;

$margin-breakout: 35px;
$default-gutter: 15px;

// Images
$image-cga-logo: url(../../images/cga-logo-med.png);
$image-cga-logo: url(../../images/cga-logo-med.png);
$image-capitol-bg: url(../../images/capitol-bg.jpg);
$image-search-bg: url(../../images/search-bg.jpg);


// Throbbers
$ajax-throbber: url(../../images/svg/loader.svg);
$ajax-throbber-alternate: url(../../images/svg/loader-alternate.svg);

// Transitions
// default
$default-transition-length: 500ms;
// scroll transition delay
$scroll-transition-delay: 400ms;
// The speed of the mobile menu open and close
$menu-transition-speed: 600ms;
// The speed of the sticky menu sliding on page scroll
$menu-scroll-slide-speed: 400ms;
// The delay of the visibility transition
$scroll-visibility-delay: $scroll-transition-delay + $menu-scroll-slide-speed;
// Speed of submenu toggles
$submenu-toggle-speed: 300ms;
// Speed of main slider transitions
$main-slider-transition-speed: 400ms;
// Speed of main slider content panel transition
$main-slider-content-panel-transition-speed: 250ms;

// Menu height variables
$main-scroll-menu-height: 61;
$agency-scroll-menu-height: 50;
$main-menu-bar-height: 48;
$agency-menu-bar-height: 34;
$adminimal-menu-height: 29;


// 1.1 Component Vars
// ------------------

// Photoswipe vars
//$pswp__show-hide-transition-duration: 333ms;
//$pswp__controls-transition-duration: 333ms;
//$pswp__background-color: #000;
//$pswp__placeholder-color: #222;
//$pswp__box-sizing-border-box: true; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: z(photoswipe);
$pswp__assets-path: '../../bower_components/photoswipe/dist/default-skin/'; // path to skin assets folder (preloader, PNG and SVG sprite)
//$pswp__error-text-color: #CCC; // "Image not loaded" text color
//$pswp__include-minimal-style: true;

// Slick Carousel vars
//$slick-font-path: "../../fonts/slick/";
$slick-font-family: "icomoon";
$slick-loader-path: "../../images/slick/";
//$slick-arrow-color: white !default;
//$slick-dot-color: black !default;
//$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: $icon-circle-arrow-left;
$slick-next-character: $icon-circle-arrow-right;
//$slick-dot-character: "•";
//$slick-dot-size: 6px;
//$slick-opacity-default: 0.75;
//$slick-opacity-on-hover: 1;
//$slick-opacity-not-active: 0.25;

// Simple Responsive Tables vars
//$simple-responsive-tables-label-color: $light-font-gray;
$simple-responsive-tables-label-size: 15px;
//$simple-responsive-tables-label-text-transform: uppercase;


// 2. Breakpoints
// --------------

/// A map of breakpoints for our site. These will override the `!default` and you can use them with the `breakpoint()` function.
/// @type Map
$breakpoints: (
  tiny: 0,
  small: 400px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);
$adminimal-menu-breakpoint: 1050px;

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-responsive-gutter: (
  small: 30px,
  medium: 60px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
/// A map of breakpoints with header sizes. These are the default font styles, and can also be applied with the `header-font-size()` mixin.
/// @type Map
$header-sizes: (
  small: (
    'h1': 36,
    'h2': 30,
    'h3': 26,
    'h4': 22,
    'h5': 18,
    'h6': 16,
  ),
  medium: (
    'h1': 56,
    'h2': 40,
    'h3': 31,
    'h4': 25,
    'h5': 22,
    'h6': 16,
  ),
);
$header-color: inherit;
$header-lineheight: 1.3;
$header-margin-bottom: 0.5rem;
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -30%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;

// 6. Abide
// --------

$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: $alert-color;
$form-label-color-invalid: $alert-color;
$input-error-color: $alert-color;
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

$accordion-background: $base-gray-light;
$accordion-plusminus: true;
$accordion-item-color: foreground($accordion-background, $primary-color);
$accordion-item-background-hover: $light-gray;
$accordion-item-padding: 1.25rem 1rem;
$accordion-content-background: $white;
$accordion-content-border: none;
$accordion-content-color: foreground($accordion-background, $primary-color);
$accordion-content-padding: 1rem;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $primary-color;

// 9. Badge
// --------

$badge-background: $primary-color;
$badge-color: foreground($badge-background);
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(11);
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-slash: true;

// 11. Button
// ----------

$button-padding: 0.85em 1em;
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: #fff;
$button-color-alt: #000;
$button-radius: $global-radius;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 0.9rem,
  large: 1.25rem,
);
$button-opacity-disabled: 0.25;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;

// 13. Callout
// -----------

$callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-padding: 1rem;
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 30%;

// 14. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: 1rem;
$closebutton-offset-vertical: 0.5rem;
$closebutton-size: 2em;
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;

// 15. Drilldown
// -------------

$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;
$drilldown-arrow-color: $primary-color;
$drilldown-background: $white;

// 16. Dropdown
// ------------

$dropdown-padding: 1rem;
$dropdown-border: 1px solid $primary-color;
$dropdown-font-size: 1rem;
$dropdown-width: 240px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
  tiny: 100px,
  small: 200px,
  large: 400px,
);

// 17. Dropdown Menu
// -----------------

$dropdownmenu-arrows: true;
$dropdownmenu-arrow-color: $anchor-color;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: $white;
$dropdownmenu-border: 1px solid $primary-color;

// 18. Flex Video
// --------------

$flexvideo-margin-bottom: rem-calc(16);
$flexvideo-ratio: 4 by 3;
$flexvideo-ratio-widescreen: 16 by 9;

// 19. Forms
// ---------

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: #333;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $base-gray-medium;
$input-prefix-padding: 1rem;
$form-label-color: $black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: #333;
$select-radius: $global-radius;
$input-color: $black;
$input-font-family: $sans-font-stack;
$input-font-size: rem-calc(16);
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $form-border-color;
$input-border-focus: 1px solid $form-border-focus-color;
$input-shadow: none;
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;

// 20. Label
// ---------

$label-background: $primary-color;
$label-color: foreground($label-background);
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;

// 21. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 22. Menu
// --------

$menu-margin: 0;
$menu-margin-nested: 1rem;
$menu-item-padding: 0.7rem 1rem;
$menu-icon-spacing: 0.25rem;
$menu-expand-max: 6;

// 23. Off-canvas
// --------------

$offcanvas-size: 250px;
$offcanvas-background: $light-gray;
$offcanvas-zindex: -1;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
$maincontent-shadow: 0 0 10px rgba($black, 0.5);

// 24. Orbit
// ---------

$orbit-bullet-background: $medium-gray;
$orbit-bullet-background-active: $dark-gray;
$orbit-bullet-diameter: 1.2rem;
$orbit-bullet-margin: 0.1rem;
$orbit-bullet-margin-top: 0.8rem;
$orbit-bullet-margin-bottom: 0.8rem;
$orbit-caption-background: rgba($black, 0.5);
$orbit-caption-padding: 1rem;
$orbit-control-background-hover: rgba($black, 0.5);
$orbit-control-padding: 1rem;
$orbit-control-zindex: 10;

// 25. Pagination
// --------------

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: foreground($pagination-item-background-current);
$pagination-item-color-disabled: $medium-gray;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;
$pagination-arrows: true;

// 26. Progress Bar
// ----------------

$progress-height: 1rem;
$progress-background: $medium-gray;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: $global-radius;

// 27. Reveal
// ----------

$reveal-background: $white;
$reveal-width: 600px;
$reveal-max-width: $global-width;
$reveal-offset: rem-calc(100);
$reveal-padding: $global-padding;
$reveal-border: 1px solid $medium-gray;
$reveal-radius: $global-radius;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($black, 0.45);

// 28. Slider
// ----------

$slider-height: 0.5rem;
$slider-width-vertical: $slider-height;
$slider-background: $light-gray;
$slider-fill-background: $medium-gray;
$slider-handle-height: 1.4rem;
$slider-handle-width: 1.4rem;
$slider-handle-background: $primary-color;
$slider-opacity-disabled: 0.25;
$slider-radius: $global-radius;
$slider-transition: all 0.2s ease-in-out;

// 29. Switch
// ----------

$switch-background: $medium-gray;
$switch-background-active: $primary-color;
$switch-height: 2rem;
$switch-height-tiny: 1.5rem;
$switch-height-small: 1.75rem;
$switch-height-large: 2.5rem;
$switch-radius: $global-radius;
$switch-margin: $global-margin;
$switch-paddle-background: $white;
$switch-paddle-offset: 0.25rem;
$switch-paddle-radius: $global-radius;
$switch-paddle-transition: all 0.25s ease-out;

// 30. Table
// ---------

$table-background: $white;
$table-color-scale: 4%;
//$table-border: 1px solid smart-scale($table-background, $table-color-scale);
$table-border: none;
$table-padding: rem-calc(12);
$table-hover-scale: 2%;
$table-row-hover: $base-gray-light;
$table-row-stripe-hover: $base-gray-medium;
$table-striped-background: smart-scale($table-background, $table-color-scale);
$table-stripe: odd;
$table-head-background: $white;
$table-foot-background: smart-scale($table-background, $table-color-scale);
$table-head-font-color: $font-gray;
$show-header-for-stacked: false;

// 31. Tabs
// --------

$tab-margin: 0;
$tab-background: $white;
$tab-background-active: $light-gray;
$tab-border: $light-gray;
$tab-item-color: foreground($tab-background, $primary-color);
$tab-item-background-hover: $white;
$tab-item-padding: 1.25rem 1.5rem;
$tab-expand-max: 6;
$tab-content-background: $white;
$tab-content-border: $light-gray;
$tab-content-color: foreground($tab-background, $primary-color);
$tab-content-padding: 1rem;

// 32. Thumbnail
// -------------

$thumbnail-border: solid 4px $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 33. Title Bar
// -------------

$titlebar-background: $black;
$titlebar-color: $white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $white;
$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0.25rem;

// 34. Tooltip
// -----------

$has-tip-font-weight: $global-weight-bold;
$has-tip-border-bottom: dotted 1px $dark-gray;
$tooltip-background-color: $black;
$tooltip-color: $white;
$tooltip-padding: 0.75rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-pip-offset: 1.25rem;
$tooltip-radius: $global-radius;

// 35. Top Bar
// -----------

$topbar-padding: 0;
$topbar-background: $white;
$topbar-title-spacing: 1rem;
$topbar-input-width: 200px;

