.hide-header {
  width    : 1200px;
  height   : 2000px;
  overflow : hidden;
  position : relative;
  iframe {
      position : absolute;
      top      : -120px;
      left     : 400px;
  }
}

iframe {
  div {
    background-color: green;
  }
}

#iframe-remote-testimony {
  border-style: none;
  width: 200%;
}

body.section-remote-testimony-sign {
  .field-name-field-body {
    margin-left: 6%;
  }
}
body.section-remote-testimony {
  .main-content-section-container {
    margin-left: 12% !important;
  }
}

.findleg {
  height: 50rem;
}
