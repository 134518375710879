/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Timeline styles
 *
 * * * * * * * * * * * * * * * * * * * * */

// Bill activity wrapper
.bill-activity {
  position: relative;
  .view-table-wrapper,
  .view-timeline-wrapper {
    display: none;
  }
  .view-select {
    position: absolute;
    display: block;
    width: 150px;
    top: 0;
    right: 0;
  }
  &.show-table-view {
    .view-table-wrapper {
      display: block;
    }
  }
  &.show-timeline-view {
    .view-timeline-wrapper {
      display: block;
    }
  }
}

// Timeline wrapper
.view-timeline-wrapper {

}

// Timeline view content container
.timeline {
  margin: 0 auto;
  @include clearfix();
  position: relative;
  padding: 30px 0;


  &:before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 6px;
    background: gray;
    display: block;
  }

  @media #{$large-up} {
    margin: 30px 0;
    &:before {
      left: 50%;
      margin-left: -3px;
    }
  }

}

// The block of timeline items on one date
.timeline-block {
  position: relative;
  margin: 50px 0;
  @include clearfix();
  clear: both;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .timeline-date {
    display: inline-block;
    position: absolute;
    margin-left: 60px;
    top: -35px;
    left: 0;
    padding: 10px 0;
  }


  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 20px;
    height: 0;
    width: 0;
    border: 14px solid transparent;
    border-left: 18px solid gray;
  }

  @media #{$large-up} {
    margin: 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      right: 50%;
      left: auto;
      border: 14px solid transparent;
      border-right: 18px solid gray;
    }
    &:nth-child(2n) {
      &:before {
        content: '';
        right: auto;
        left: 50%;
        border: 14px solid transparent;
        border-left: 18px solid gray;
      }
      .timeline-date {
        left: auto;
        right: 0;
        text-align: left;
      }
      .timeline-item {
        float: right;
      }
    }

    .timeline-date {
      position: absolute;
      width: 45%;
      margin-left: 0;
      top: -35px;
      left: 0;
      font-size: rem-calc(16);
      text-align: right;
    }
  }


  // A single timeline event, possibly a views row
  // All events on one day share the same timeline item
  .views-row,
  .timeline-item {
    position: relative;
    margin-left: 60px;
    background: #eee;
    padding: 1em;
    box-shadow: 0 3px 0 #ddd;
    z-index: 2;
    margin-top: 7px;
    clear: both;
    @include clearfix();

    h3 {
      font-size: rem-calc(16);
    }

    p {
      font-size: rem-calc(16);
      margin: 0;
    }

    .timeline-toggle {
      position: absolute;
      display: block;
      top: 10px;
      right: 10px;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      border: 1px solid #666;
      background: #ccc;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: 50%;
        left: 50%;
        margin-top: -4px;
        margin-left: -6px;
        display: block;
        border-top: #333 8px solid;
        border-left: transparent 6px solid;
        border-right: transparent 6px solid;
        transition: transform 500ms;
      }
      &.open-toggle:after {
        transform: rotate(180deg);
        border-top-color: #777;
      }
    }

    .toggle-content {
      display: none;
      padding: 5px 0;
    }

    @media #{$large-up} {
      margin-left: 0;
      padding: rem-calc(15);
      width: 45%;

      &:before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: green;
      }

    }
  }
}

