////
/// Generally useful mixins, not related to the project
/// @group e3
////


/// Mixin to replicate the useful bootstrap media object pattern. Use on the container.
@mixin media() {
  overflow: hidden;
  zoom: 1;
}
/// Bootstrap media object pattern - Use on body
@mixin media-body($alignment: top) {
  display: table-cell;
  vertical-align: $alignment;
  width: 10000px; // force body to take up the most space
}
/// Bootstrap media object pattern - Use on left panel
/// @param {String} $alignment [top] - Set vertical alignment for this table cell
/// @param {Number} $padding [10] - Set padding-right for this table cell
@mixin media-left($alignment: top, $padding: 10) {
  display: table-cell;
  vertical-align: $alignment;
  padding-right: #{$padding}px;
  // If the media-left size is NOT specifically set and the contents is an image
  // the image cannot have max-width or width set to 100%
  img {
    max-width: initial;
  }
}
/// Bootstrap media object pattern - Use on right panel
/// @param {String} $alignment [top] - Set vertical alignment for this table cell
/// @param {Number} $padding [10] - Set padding-left for this table cell
@mixin media-right($alignment: top, $padding: 10) {
  display: table-cell;
  vertical-align: $alignment;
  padding-left: #{$padding}px;
}


/// Vertical align with transform (ie9 up)
/// @param {String} $horizontal-position [left] - Sets the chosen value to 0
@mixin v-align-transform($horizontal-position: left) {
  position: absolute;
  display: block;
  //width: 100%;
  top: 50%;
  transform: translateY(-50%);
  @if ($horizontal-position == left) {
    left: 0;
  } @else {
    right: 0;
  }
}

/// Horizontal align with transform (ie9 up)
@mixin h-align-transform() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/// Triangle mixin
/// @param {String} $direction [top] - The direction the arrow points
/// @param {String} $color [$primary-color] - The arrow color
/// @param {String] $height [10px] - The arrow height (length)
/// @param {String} $width [10px] - The arrow width
@mixin triangle-shape($direction: top, $color: $primary-color, $height: 10px, $width: 10px) {
  @if ($direction == top) {
    border-bottom: $height solid $color;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
  } @else if ($direction == bottom) {
    border-top: $height solid $color;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
  } @else if ($direction == left) {
    border-right: $height solid $color;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
  } @else if ($direction == right) {
    border-left: $height solid $color;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
  }
}

/// Background image cover mixin
@mixin bg-cover() {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
/// Background image contain mixin
@mixin bg-contain() {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

/// Create a full-width background color with a pseudo element for a non-full-width element
/// @param {String} $color - Background color
/// @param {String} $pseudo-element [before] - Choose the pseudo element [before|after]
/// @param {Boolean} $addRelative [true] - Add position relative to the element
@mixin full-width-bg-color($color, $pseudo-element: before, $addRelative: true) {
  @if ($addRelative) {
    position: relative;
  }

  &:#{$pseudo-element} {
    content: '';
    position: absolute;
    width: 500%;
    top: 0;
    left: -150%;
    height: 100%;
    background: $color;
    z-index: -1;
  }
}

/// Icomoon mixing to add the default icomoon styles for font icons
@mixin icomoon() {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
