/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Menu styles!
 *
 * * * * * * * * * * * * * * * * * * * * */

.more-dropdown-link:hover {
  cursor: default;
}

// Main Menu
.menu-name-main-menu {
  @include breakpoint(xlarge) {
    &.menu-block-wrapper .dropdown.is-dropdown-submenu.first-sub {
      @include h-align-transform();
    }
    .is-down-arrow > a:after {
      display: none;
    }
    .is-dropdown-submenu {
      border: none;
      background-color: $primary-color;
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        z-index: z(defined);
        @include h-align-transform();
        @include triangle-shape(bottom, $white);
      }
      & > li:first-child > a {
        padding-top: 15px;
      }
      a {
        color: $white;
        text-align: center;
        line-height: 1.2;
        padding: 10px;
        font-size: 15px;
        &:hover,
        &:focus,
        &.active {
          background-color: $primary-color-lighter;
          color: $white;
        }
      }
    }
    & > .menu__item {
      & > a {
        padding: 20px 18px;
        font-size: 14px;
        letter-spacing: 1px;
        color: $body-font-color;
        text-transform: uppercase;
      }
      &:first-child > a {
        padding-left: 16px;
      }
      &:last-child > a {
        padding-right: 16px;
      }
      &.is-active > a,
      & > a:hover,
      & > a:focus {
        color: $primary-color;
      }
    }
    & > .active-trail > a {
      @include menu-active-trail(main);
    }
    & > .is-active > a:before {
      display: none;
    }
  }
}

// Utility Nav in header

#block-menu-block-2 {
  .menu-name-menu-secondary-menu {
    @include breakpoint(large) {
      .is-dropdown-submenu {
        @include h-align-transform();
      }
    }
  }
}
.menu-name-menu-secondary-menu {
  a {
    color: $medium-font-gray;
    font-size: 14px;
  }
  @include breakpoint(xlarge) {
    text-align: right;
    .is-dropdown-submenu.dropdown {
      border: none;
      background-color: $primary-color;
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        z-index: z(dropdowns, above);
        @include h-align-transform();
        @include triangle-shape(bottom, $white);
      }
      a {
        color: $white;
        text-align: center;
        line-height: 1.2;
        padding: 10px;
        font-size: 15px;
        &:hover,
        &:focus,
        &.active {
          background-color: $primary-color-lighter;
        }
      }
    }
    & > li {
      position: relative;
      display: inline-block;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        height: 14px;
        margin-top: -7px;
        border-left: 1px solid $light-font-gray;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      &:last-child a {
        padding-right: 0;
      }
      &.is-active > a,
      & > a:hover,
      & > a:focus {
        color: $primary-color;
      }
    }
    .is-dropdown-submenu-parent.is-down-arrow > a {
      padding-right: 30px;
      &:after {
        content: $icon-arrow-down;
        @include icomoon();
        border: none;
        font-size: 12px;
        color: $primary-color;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        right: 5px;
        top: 50%;
        margin: -10px 0 0 0;
      }
    }
    .is-dropdown-submenu {
      & > li:first-child > a {
        padding-top: 15px;
      }
    }
  }
}

// Agency Menu
.node-type-agency,
.node-type-agency-detail,
.node-type-minisite-page{
  .region-content-top .block-menu-block,
  .agency-scroll-menu {
    background-color: $base-gray;
    .menu-block-wrapper {
      @include content-width(menu);
    }
    a {
      color: $white;
      font-size: 14px;
    }
    @include breakpoint(large) {
      .menu-block-wrapper {
        .menu__item {
          & > a {
            color: $lightest-font-gray;
          }
          &.is-active > a,
          & > a:hover,
          & > a:focus {
            color: $white;
          }
        }
        .dropdown.is-dropdown-submenu.first-sub {
          @include h-align-transform();
        }
        .is-down-arrow > a:after {
          display: none;
        }
        & > li > a {
          padding: 18px 16px;
        }
        & > .active-trail > a {
          @include menu-active-trail(agency);
        }
        & > .is-active > a:before {
          display: none;
        }
        .is-dropdown-submenu {
          border: none;
          background-color: $menu-gray-medium;
          &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            z-index: z(dropdowns, above);
            @include h-align-transform();
            @include triangle-shape(bottom, $base-gray);
          }
          a {
            color: $white;
            text-align: center;
            line-height: 1.2;
            padding: 10px;
            font-size: 15px;
            &:hover,
            &:focus,
            &.active {
              background-color: $menu-gray-light;
            }
          }
          & > li:first-child > a {
            padding-top: 15px;
          }
        }
      }
    }
    .menu__item .agency-title {
      display: block;
      max-width: 200px;
      color: $white;
      font-family: $serif-font-stack;
      line-height: 1.1;
    }
  }
}



/**
 * Scroll menus
 */
.main-scroll-menu {
  border-bottom: 4px solid $primary-color;
  background-color: $white;
  .menu-name-main-menu {
    @include content-width(menu);
    & > .menu__item {
      padding: 5px 0;
      & > a {
        padding: 17px 12px;
      }
    }
    .menu__item a {
      font-size: 13px;
    }
    .menu__item:first-child a {
      padding-left: 15px;
    }
    .menu__item:last-child a {
      padding-right: 15px;
    }
    .active-trail > a:before {
      bottom: -5px;
    }
  }
  .home-menu-item {
    .home-menu-link {
      background-image: $image-cga-logo;
      text-indent: -1000px;
      width: 75px;
      @include bg-contain();
    }
  }
}

/**
 * Mobile Menus
 */
.mobile-main-menu-bar {
  position: relative;
  background-color: $white;
  @include cga-border(bottom);
  .site-title {
    display: inline-block;
    font-size: 20px;
    color: $body-font-color;
    padding: 2px 0 2px 40px;
    background-image: $image-cga-logo;
    @include bg-contain();
    background-position: left center;
  }
  .mobile-main-menu-toggle {
    @include vertical-center();
    right: 15px;
    text-indent: -2000px;
    overflow: hidden;
    height: 30px;
    width: 60px;
    padding-left: 30px;
    .animated-toggle {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.mobile-agency-menu-bar {
  background-color: $base-gray;
  color: $white;
  position: relative;
}
.mobile-agency-menu-toggle {
  @include vertical-center();
  right: 15px;
  color: $white;
  width: 100px;
  padding-left: 100px;
  height: 30px;
  overflow: hidden;
  &:after {
    content: $icon-arrow-down;
    @include icomoon();
    position: absolute;
    top: 0;
    right: 6px;
    height: 30px;
    line-height: 30px;
    transition: transform $menu-transition-speed;
  }
  &:focus:after,
  &:hover:after {
    color: $white;
  }
}
.region-content-top {
  .menu-block-wrapper {
    display: none;
  }
  .inline-agency-menu-bar {
    .inline-mobile-agency-menu-toggle {
      display: block;
      position: relative;
      padding: 7px 40px 7px 15px;
      &:after {
        content: $icon-arrow-down;
        @include icomoon();
        font-size: 14px;
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -7px;
        right: 15px;
        transition: transform $menu-transition-speed;
      }
      span {
        color: $white;
      }
      color: $white;
    }
  }
  @include breakpoint(xlarge) {
    .menu-block-wrapper {
      display: block;
    }
    .inline-agency-menu-bar {
      display: none;
    }
  }
}
.agency-mobile-menu-open {
  .mobile-agency-menu-toggle,
  .inline-mobile-agency-menu-toggle {
    &:after {
      transform: rotateX(180deg);
    }
  }
}
// General mobile menu styles
.mobile-menu-wrapper {
  .menu-name-main-menu > .menu__item:last-child > a {
    // For a bug, maybe try to sort out and remove
    padding-right: 0;
  }
  .menu__item {
    display: block;
    position: relative;
    .menu__link {
      padding: 20px 15px;
      display: block;
      font-size: 15px;
      transition: background-color 300ms;
      color: $white;
      &:before {
        display: none;
      }
    }
    .mobile-menu-toggle {
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      height: 55px;
      padding: 0 30px 0 0;
      overflow: hidden;
      color: transparent;
      font-size: 0;
      &:before {
        display: none;
      }
      &:after {
        content: $icon-arrow-down;
        @include icomoon();
        display: block;
        position: absolute;
        right: 15px;
        top: 0;
        width: 40px;
        height: 55px;
        line-height: 55px;
        color: $white;
        font-size: 14px;
        text-align: center;
        transition: transform $submenu-toggle-speed;
      }
      &.toggle-open:after {
        transform: rotateX(180deg);
      }
    }
  }
  .menu-block-wrapper > li > ul {
    display: none;
  }
  .menu-block-wrapper:not(.menu-name-menu-secondary-menu) {
    & > .menu__item {
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 15px;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
  .menu-block-wrapper {
    .menu__item.active {
      @include menu-active-trail(mobile);
      z-index: z(defined);
    }
  }
}
// Main mobile menu styles
.mobile-menu-wrapper-main {
  background-color: $primary-color;
  .menu-name-main-menu {
    overflow: hidden;
    .menu__item {
    }
    // First level nav items
    & > .menu__item {
      & > .menu__link {
        &:hover {
          background-color: $primary-color-lighter;
        }
      }
      // Second level nav items
      & > ul {
        & > .menu__item {
          & > .menu__link {
            background: $primary-color-lighter;
            padding: 12px 15px;
            line-height: 1.6;
            &:hover {
              background-color: scale-color($primary-color-lighter, $lightness: 10%);
            }
          }
        }
      }
    }
  }
}
// Agency mobile menu styles
.mobile-menu-wrapper-agency {
  background-color: $menu-gray-medium;
  .menu-block-wrapper {
    overflow: hidden;
    .menu__item {
    }
    // First level nav items
    & > .menu__item {
      & > .menu__link {
        &:hover {
          background-color: $menu-gray-light;
        }
      }
      // Second level nav items
      & > ul {
        & > .menu__item {
          & > .menu__link {
            background: $menu-gray-light;
            padding: 12px 15px;
            &:hover {
              background-color: scale-color($menu-gray-light, $lightness: 10%);
            }
          }
        }
      }
    }
  }
}




/**
 * Menu display/hide functionality
 * Menu height vars defined in _settings.scss
 * Accessibility handled in _accessibility.scss
 */
// Scroll menus
.scroll-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: z(scroll-menus);
  transition: transform $menu-scroll-slide-speed;

  .scroll-menus {
    transition: transform $menu-scroll-slide-speed;
    transform: translateY(-#{$main-scroll-menu-height + $agency-scroll-menu-height}px);
  }
  &.active-menus {
    .scroll-menus {
      transform: translateY(-#{$main-scroll-menu-height}px);
    }
    &.show-main-menu {
      .scroll-menus {
        transform: translateY(0);
      }
    }
  }

  // Hide for small screens
  // @TODO: Add menu breakpoint/adjust current breakpoints
  @include breakpoint(large down) {
    display: none;
  }

}


// Mobile menu bar behavior
.mobile-menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  @include breakpoint(medium) {
    padding: 5px 15px;
  }
  &.mobile-main-menu-bar {
    z-index: z(main-sticky-bar);
    top: 0;
  }
  &.mobile-agency-menu-bar {
    z-index: z(agency-sticky-bar);
    // compensate a couple px to prevent seeing between the gap between menu bars
    top: #{$main-menu-bar-height - 2}px; // height of bar above - 2
    padding-top: 7px;
  }
}
.mobile-menu-bar {
  visibility: hidden;
  transition: transform $menu-scroll-slide-speed, visibility 0ms;
  transition-delay: 0ms, $menu-scroll-slide-speed;
  transform: translateY(-#{$main-menu-bar-height + $agency-menu-bar-height}px);
  &.active-menus {
    transform: translateY(-#{$main-menu-bar-height}px);
    &.mobile-agency-menu-bar {
      visibility: visible;
      transition-delay: 0ms, 0ms;
    }
    &.show-main-menu {
      transform: translateY(0);
      &.mobile-main-menu-bar {
        visibility: visible;
        transition-delay: 0ms, 0ms;
      }
    }
  }
}
.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 100%;
  width: 100%;
  transition: transform $menu-transition-speed, visibility 0ms;
  transition-delay: 0ms, $menu-transition-speed;
  transform: translateY(-100%);
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &.show-mobile-menu {
    transform: translateY(0);
    visibility: visible;
    transition-delay: 0ms, 0ms;
  }
  .mobile-menu-main,
  .mobile-menu-agency {

  }
  &.mobile-menu-wrapper-main {
    padding-top: #{$main-menu-bar-height}px;
    z-index: z(main-sticky-menu);
  }
  &.mobile-menu-wrapper-agency {
    padding-top: #{$main-menu-bar-height + $agency-menu-bar-height}px;
    z-index: z(agency-sticky-menu);
  }
}

.mobile-menu-bar,
.mobile-menu-wrapper {
  @include breakpoint(xlarge) {
    display: none;
  }
}

// While a menu item is open, make mobile menus in active state and overflow hidden body, to freeze it on desktop
// Mobile will likely just have to be wiggly
.main-mobile-menu-open,
.agency-mobile-menu-open {
  overflow: hidden;
  .mobile-menu-bar {
    &.mobile-agency-menu-bar,
    &.mobile-main-menu-bar {
      transform: translateY(0);
      visibility: visible;
      transition-delay: 0ms, 0ms;
    }
    //&.mobile-main-menu-bar {
    //  transform: translateY(0);
    //}
  }
}


// Adjust for logged in admin menu
.adminimal-menu {
  .scroll-menu-wrapper {
    top: #{$adminimal-menu-height}px;
  }
  .mobile-menu-bar {
    &.mobile-main-menu-bar {
      @include breakpoint($adminimal-menu-breakpoint) {
        top: #{$adminimal-menu-height}px; // Add 29px to normal value
      }
    }
    &.mobile-agency-menu-bar {
      @include breakpoint($adminimal-menu-breakpoint) {
        top: #{$main-menu-bar-height + $adminimal-menu-height -2}px; // Add 29px to normal value
      }
    }
  }
  .mobile-menu-wrapper {
    &.mobile-menu-wrapper-main {
      @include breakpoint($adminimal-menu-breakpoint) {
        padding-top: #{$main-menu-bar-height + $adminimal-menu-height}px;
      }
    }
    &.mobile-menu-wrapper-agency {
      @include breakpoint($adminimal-menu-breakpoint) {
        padding-top: #{$main-menu-bar-height + $agency-menu-bar-height + $adminimal-menu-height}px;
      }
    }
  }
}
