/**
 * Foundation for Sites by ZURB
 * Version 6.1.2
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */


// Add settings then optionally import all of foundation, or individual components
// Note: Import does not by default output any css, but just makes it available

@import "settings";
@import "foundation";


// Sass utilities
//@import 'util/util';
//
// Global variables and styles
//@import 'global';
//
// Components
//@import 'grid/grid';
//@import 'typography/typography';
//@import 'forms/forms';
//@import 'components/visibility';
//@import 'components/float';
//@import 'components/button';
//@import 'components/button-group';
//@import 'components/accordion-menu';
//@import 'components/accordion';
//@import 'components/badge';
//@import 'components/breadcrumbs';
//@import 'components/callout';
//@import 'components/close-button';
//@import 'components/drilldown';
//@import 'components/dropdown-menu';
//@import 'components/dropdown';
//@import 'components/flex-video';
//@import 'components/label';
//@import 'components/media-object';
//@import 'components/menu';
//@import 'components/off-canvas';
//@import 'components/orbit';
//@import 'components/pagination';
//@import 'components/progress-bar';
//@import 'components/reveal';
//@import 'components/slider';
//@import 'components/sticky';
//@import 'components/switch';
//@import 'components/table';
//@import 'components/tabs';
//@import 'components/title-bar';
//@import 'components/top-bar';
//@import 'components/thumbnail';
//@import 'components/tooltip';



// Include this mixin for outputting ALL css, or selectively include components to output
@mixin foundation-everything($flex: false) {
  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @include foundation-flex-grid;
  }
  @include foundation-typography;
  @include foundation-button;
  @include foundation-forms;
  @include foundation-visibility-classes;
  @include foundation-float-classes;
  @include foundation-accordion;
  @include foundation-accordion-menu;
  @include foundation-badge;
  @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-close-button;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-flex-video;
  @include foundation-label;
  @include foundation-media-object;
  @include foundation-menu;
  @include foundation-off-canvas;
  @include foundation-orbit;
  @include foundation-pagination;
  @include foundation-progress-bar;
  @include foundation-slider;
  @include foundation-sticky;
  @include foundation-reveal;
  @include foundation-switch;
  @include foundation-table;
  @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  @include foundation-tooltip;
  @include foundation-top-bar;
}

@include foundation-global-styles;
@include foundation-typography;
@include foundation-forms;
@include foundation-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
@include foundation-table;
// Only use this if we use F6 accordion AND actually want the styles once theming begins
@include foundation-accordion;
