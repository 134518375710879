
/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Advanced Page Specific Styels
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Advanced Page Specific Styles
 */

 .node-type-advanced-page{
    .region-bottom{
      margin-left: 10%;
      margin-right: 10%;
    }
    .field-name-field-bottom{

     .paragraphs-item-component-callout-bar{
      img{
        width: 100%;
      }
     }
    }
 }
