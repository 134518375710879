/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Legislator styles
 *
 * * * * * * * * * * * * * * * * * * * * */

.node-type-schedule-item {
  // Layout
  @include fullwidth-layout();

  .node-title {
    margin: 0;
  }

  .calendar-meta {
    @include schedule-header(capitalize);
    margin: 30px 0 15px 0;
    .audio-icon-link{
      float: right;
      padding-bottom: 30px;
    }
  }

  .calendar-details {
    display: inline-block;
    margin-top: 3px;
    font-size: 16px;
    text-transform: none;
    color: $light-font-gray;
  }

}

.node-schedule-item.node-teaser {
  .event-content {
    padding-right: 0;
  }
}
