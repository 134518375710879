/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Font-face includes
 *
 * * * * * * * * * * * * * * * * * * * * */


@font-face {
  font-family: 'icomoon';
  src:url('../../fonts/icomoon/fonts/icomoon.eot?y73jg5');
  src:url('../../fonts/icomoon/fonts/icomoon.eot?#iefixy73jg5') format('embedded-opentype'),
  url('../../fonts/icomoon/fonts/icomoon.ttf?y73jg5') format('truetype'),
  url('../../fonts/icomoon/fonts/icomoon.woff?y73jg5') format('woff'),
  url('../../fonts/icomoon/fonts/icomoon.svg?y73jg5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-coins: "\e96b";
$icon-person: "\e973";
$icon-capitol: "\e968";
$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-circle-arrow-down: "\e904";
$icon-circle-arrow-left: "\e905";
$icon-circle-arrow-right: "\e906";
$icon-circle-arrow-up: "\e907";
$icon-democrat: "\e932";
$icon-republican: "\e931";
$icon-twitter: "\e92e";
$icon-facebook: "\e92f";
$icon-contact: "\e930";
$icon-close: "\e92c";
$icon-error: "\e600";
$icon-status: "\e601";
$icon-warning: "\e602";
$icon-share: "\e908";
$icon-testify: "\e909";
$icon-calendar: "\e928";
$icon-video: "\e929";
$icon-search: "\e92a";
$icon-document: "\e92b";
$icon-money-stack: "\e913";
$icon-agriculture-tid-37: "\e90a";
$icon-business-dev-tid-42: "\e90b";
$icon-capital-construction-tid-41: "\e90c";
$icon-children-domestic-tid-246: "\e90d";
$icon-civil-law-tid-46: "\e90e";
$icon-crimes-tid-45: "\e90f";
$icon-elections-tid-52: "\e910";
$icon-energy-tid-40: "\e911";
$icon-finance-commerce-tid-236: "\e912";
$icon-fiscal-taxes-tid-65: "\e92d";
$icon-gaming-tid-63: "\e914";
$icon-healthcare-tid-56: "\e915";
$icon-higher-education-tid-50: "\e916";
$icon-housing-tid-58: "\e917";
$icon-human-services-tid-57: "\e918";
$icon-immigration-tid-69: "\e919";
$icon-judicial-tid-47: "\e91a";
$icon-labor-tid-59: "\e91b";
$icon-liquor-tobacco-marijuana-tid-62: "\e91c";
$icon-local-government-tid-54: "\e91d";
$icon-natural-resources-tid-38: "\e91e";
$icon-public-heath-tid-60: "\e91f";
$icon-revenue-budget-tid-66: "\e920";
$icon-school-finance-tid-49: "\e921";
$icon-state-government-tid-53: "\e922";
$icon-telecom-it-tid-241: "\e923";
$icon-transportation-tid-67: "\e924";
$icon-water-tid-39: "\e925";
$icon-insurance-tid-44: "\e926";
$icon-military-tid-55: "\e927";
$icon-speaker-volume-up: "\1f50a";

.icon-republican:before {
  content: $icon-republican;
}
.icon-democrat:before {
  content: $icon-democrat;
}
.icon-twitter:before {
  content: $icon-twitter;
}
.icon-facebook:before {
  content: $icon-facebook;
}
.icon-contact:before {
  content: $icon-contact;
}
.icon-money-stack:before {
  content: $icon-money-stack;
}
.icon-calendar:before {
  content: $icon-calendar;
}
.icon-video:before {
  content: $icon-video;
}
.icon-search:before {
  content: $icon-search;
}
.icon-document:before {
  content: $icon-document;
}
.icon-share:before {
  content: $icon-share;
}
.icon-testify:before {
  content: $icon-testify;
}
.icon-arrow-down:before {
  content: $icon-arrow-down;
}
.icon-arrow-left:before {
  content: $icon-arrow-left;
}
.icon-arrow-right:before {
  content: $icon-arrow-right;
}
.icon-arrow-up:before {
  content: $icon-arrow-up;
}
.icon-circle-arrow-down:before {
  content: $icon-circle-arrow-down;
}
.icon-circle-arrow-left:before {
  content: $icon-circle-arrow-left;
}
.icon-circle-arrow-right:before {
  content: $icon-circle-arrow-right;
}
.icon-circle-arrow-up:before {
  content: $icon-circle-arrow-up;
}
.icon-close:before {
  content: $icon-close;
}
.icon-error:before {
  content: $icon-error;
}
.icon-status:before {
  content: $icon-status;
}
.icon-warning:before {
  content: $icon-warning;
}
.icon-agriculture:before {
  content: $icon-agriculture-tid-37;
}
.icon-business-dev:before {
  content: $icon-business-dev-tid-42;
}
.icon-capital-construction:before {
  content: $icon-capital-construction-tid-41;
}
.icon-children-domestic:before {
  content: $icon-children-domestic-tid-246;
}
.icon-civil-law:before {
  content: $icon-civil-law-tid-46;
}
.icon-crimes:before {
  content: $icon-crimes-tid-45;
}
.icon-elections:before {
  content: $icon-elections-tid-52;
}
.icon-energy:before {
  content: $icon-energy-tid-40;
}
.icon-finance-commerce:before {
  content: $icon-finance-commerce-tid-236;
}
.icon-fiscal-taxes:before {
  content: $icon-fiscal-taxes-tid-65;
}
.icon-gaming:before {
  content: $icon-gaming-tid-63;
}
.icon-healthcare:before {
  content: $icon-healthcare-tid-56;
}
.icon-higher-education:before {
  content: $icon-higher-education-tid-50;
}
.icon-housing:before {
  content: $icon-housing-tid-58;
}
.icon-human-services:before {
  content: $icon-human-services-tid-57;
}
.icon-immigration:before {
  content: $icon-immigration-tid-69;
}
.icon-judicial:before {
  content: $icon-judicial-tid-47;
}
.icon-labor:before {
  content: $icon-labor-tid-59;
}
.icon-liquor-tobacco-marijuana:before {
  content: $icon-liquor-tobacco-marijuana-tid-62;
}
.icon-local-government:before {
  content: $icon-local-government-tid-54;
}
.icon-natural-resources:before {
  content: $icon-natural-resources-tid-38;
}
.icon-public-health:before {
  content: $icon-public-heath-tid-60;
}
.icon-revenue-budget:before {
  content: $icon-revenue-budget-tid-66;
}
.icon-school-finance:before {
  content: $icon-school-finance-tid-49;
}
.icon-state-government:before {
  content: $icon-state-government-tid-53;
}
.icon-telecom-it:before {
  content: $icon-telecom-it-tid-241;
}
.icon-transportation:before {
  content: $icon-transportation-tid-67;
}
.icon-water:before {
  content: $icon-water-tid-39;
}
.icon-insurance:before {
  content: $icon-insurance-tid-44;
}
.icon-military:before {
  content: $icon-military-tid-55;
}
.icon-audio-up:before {
  content: $icon-speaker-volume-up;
}
.icon-capitol:before {
  //content: $icon-capitol;
}
