////
/// CGA Specific Mixins
/// @group cga
////
@mixin circle($width, $color, $display) {

  img {
    left: 0;
    top: -5px;
    display: $display;
    width: $width;
    height: $width;
    line-height: $width;
    background: $color;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
  }
}

/// Headshot teaser style
@mixin headshot-teaser() {
  a {
    position: relative;
    display: block;
    width: 80px;
    height: 0;
    padding-top: 100%;
    border-radius: $global-rounded;
    overflow: hidden;
    z-index: z(defined);
  }
  img {
    position: absolute;
    top: -5px;
    left: 0;
    display: block;
    width: 100%;
  }
}

/// Additional base styles to be used for all buttons
/// @group buttons
@mixin cga-button() {
  font-family: $sans-font-stack;
}

/// Styles to add to button styles to make it a CTA. This should be added in addition to `@include button()`
/// @param {String} $icon [$icon-circle-arrow-right] - The icon to use in the CTA button
/// @param {String} $color [rgba(255,255,255,0.5)] - The icon color
/// @group buttons
@mixin cta-button($icon: $icon-circle-arrow-right, $color: rgba(255,255,255,0.5), $position: after) {
  position: relative;
  @if ($position == after) {
    padding-right: 2.8em;
  } @else if ($position == before) {
    padding-left: 2.8em;
  }
  &:after {
    content: $icon;
    @include icomoon();
    @include v-align-transform(right);
    width: auto;
    font-size: 1.4em;
    color: $color;
    @if ($position == after) {
      right: 14px;
    } @else if ($position == before) {
      left: 14px;
      right: auto;
    }
  }
}

/// The default button styles for a gold button
/// @group buttons
@mixin button-gold() {
  @include button($background: $secondary-color, $background-hover: darken($secondary-color, 5%));
}

/// Compilation of mixins to create the default cta button styles for CGA. This use this mixin for desired default
/// cta buttons.
/// @param {String} $type [default] - Choose the type of CTA [default|gold]
/// @group buttons
@mixin default-cta-button($type: default) {
  @if ($type == default) {
    @include button();
  } @else if ($type == gold) {
    @include button-gold();
  }
  @include cga-button();
  @include cta-button();
}

/// Add an icon-prefix to an element
/// @param {String} $icon - Choose an icon (the pseudo element content value)
/// @param {Number} $icon-size - font-size for the icon
/// @group buttons
@mixin icon-inline-attach($icon, $icon-size: 18, $position: before) {
  position: relative;
  display: inline-block;
  @if ($position == before) {
    padding-left: #{$icon-size * 1.6}px;
  } @else if ($position == after) {
    padding-right: #{$icon-size * 1.6}px;
  }
  &:before {
    content: $icon;
    @include icomoon();
    @include v-align-transform(left);
    width: auto;
    left: #{$icon-size * 0.3}px;
    font-size: #{$icon-size}px;
    @if ($position == before) {
      left: #{$icon-size * 0.3}px;
    } @else if ($position == after) {
      left: auto;
      right: #{$icon-size * 0.3}px;
    }
  }
}

/// Create the data uri for the dropdown background image
/// @param {String} $color [$border-dark-gray] - Dropdown image color
@mixin select-dropdown-background($color: $border-dark-gray) {
  $rgb: 'rgb%28#{red($color)}, #{green($color)}, #{blue($color)}%29';
  $svg: "<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='40' height='34' viewBox='0 0 40 34'><path style='fill: #{$rgb}' d='M32.6,14.2c0.7-0.7,0.7-1.9,0-2.6c-0.7-0.7-1.8-0.7-2.6,0l-6.9,7.1l-6.9-7c-0.7-0.7-1.8-0.7-2.6,0c-0.3,0.4-0.5,0.8-0.5,1.3s0.2,0.9,0.6,1.3l8.2,8.3c0.7,0.7,1.8,0.7,2.6,0L32.6,14.2z'/><rect style='fill: #{$rgb}' x='3.4' width='1' height='34'/></svg>";
  background-image: svg-url($svg);
}

/// Default border styles used for CGA
/// @param {String} $position [all] - Border position [all|top|bottom|left|right]
/// @param {String} $width [4px] - Border width
/// @param {String} $color [$primary-color] - Border color
@mixin cga-border($position: all, $width: 4px, $color: $primary-color) {
  @if ($position == all) {
    border: $width solid $color;
  } @else {
    border-#{$position}: $width solid $color;
  }
}

/// Content width definitions for the site
/// default - The default site max-width (1200px) along with 50px padding on either side
/// window - Full width, no max-width, no padding
/// breakout - The default site max-width along with only gutter (15px) padding on either side
/// menu - Reduced max width (1130px) in order to account for extra padding for default content width on full screen
/// tighten - Less than default max width for the site (900px) with the 50px padding
/// narrow - Max-width of 600px and gutter padding
/// @param {String} $format [default] - Choose the content width format [default|window|breakout|menu|tighten|narrow]
@mixin content-width($format: default) {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @if ($format == window) {
    max-width: 100%;
    padding: 0;
  } @else if ($format == breakout) {
    max-width: 1200px;
    padding: 0 15px;
  } @else if ($format == menu) {
    max-width: 1130px;
    padding: 0;
  } @else if ($format == tighten) {
    max-width: 900px;
    padding: 0 30px;
    @include breakpoint(medium) {
      padding: 0 50px;
    }
  } @else if ($format == narrow) {
    max-width: 600px;
    padding: 0 15px;
  } @else {
    max-width: 1200px;
    padding: 0 30px;
    @include breakpoint(medium) {
      padding: 0 50px;
    }
  }
}

/// Set a content type to the fullwidth layout.
@mixin fullwidth-layout() {
  .main-content-section {
    @include content-width(window);
    & > .main-content {
      @include content-width(window);
    }
    & > .aside {
      @include content-width(window);
    }
  }
}

/// Styles for the breakout container
@mixin breakout-container($border: true, $padding: both) {
  position: relative;
  @if ($border == true) {
    @include cga-border(all, 2px, $base-gray-light);
  }
  @if ($padding == both) {
    padding: 35px 15px;
  } @else if ($padding == sides) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include breakpoint(medium) {
    @if ($padding == both) {
      padding: 35px;
    } @else if ($padding == sides) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

/// Styles for a breakout CTA that is positioned absolutely on the bottom line of the breakout container. This mixin
/// only handles the position of the cta.
/// @group buttons
@mixin breakout-cta() {
  position: absolute;
  bottom: -21px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

/// All defaults for a breakout-cta button. If you want position and style defaults for a breakout cta, use this mixin.
/// @group buttons
@mixin breakout-cta-all-defaults() {
  @include button();
  @include cga-button();
  @include cta-button();
  @include breakout-cta();
}

/// Create the calendar icon for CGA output calendar markup
/// @param {String} $color - The calendar color
@mixin calendar-icon($color: $primary-color) {
  border: 1px solid $color;
  width: 70px;
  .calendar-icon-top {
    background-color: $color;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 8px 0;
    line-height: 1;
    font-family: $sans-font-stack;
  }
  .calendar-icon-bottom {
    text-align: center;
    font-size: 34px;
    padding: 4px 0;
    line-height: 1;
    font-family: $sans-font-stack;
    color: $color;
  }
}

/// Default implementation of bootstrap media object for members/profile layout
/// @param {Number} $padding [10] -
/// @param {String} $image-container ['.member-image'] - The selector for the left item of the media object
/// @param {String} $content-container ['.member-details'] - The selector for the body of the media object
@mixin member-layout($padding: 10, $image-container: '.member-image', $content-container: '.member-details') {
  @include media();
  #{$image-container} {
    @include media-left(middle, $padding);
    @include headshot-teaser();
  }
  #{$content-container} {
    @include media-body(middle);
  }
}

/// Vertical rhythm mixin - try to use `bottom` in nearly all cases for consistency and compatibility
/// @param {String} $type [default] - The type of vertical space [default|medium|small|tiny]
/// @param {String} $position [bottom] - The position of the margin [top|bottom]
@mixin vertical-space($type: default, $position: bottom) {
  @if ($type == default) {
    margin-#{$position}: 80px;
  } @else if ($type == medium) {
    margin-#{$position}: 60px;
  } @else if ($type == small) {
    margin-#{$position}: 40px;
  } @else if ($type == tiny) {
    margin-#{$position}: 20px;
  }
}

/// Vertical space for titles
/// @param {String} $type [section-title] - The type of vertical header space [section-title|main-title|small-title]
@mixin title-space($type: section-title) {
  @if ($type == main-title) {
    margin-bottom: 40px;
  } @else if ($type == section-title) {
    margin-bottom: 25px;
  } @else if ($type == small-title) {
    margin-bottom: 10px;
  }
}

/// Subheader font style
@mixin sub-header-font-styles() {
  @include header-font-size(h4);
  font-family: $sans-font-stack;
  line-height: 1.3;
}

/// Block header font style
@mixin block-header-font-styles() {
  font-family: $sans-font-stack;
  font-size: 20px;
  color: $light-font-gray;
  letter-spacing: 0.5px;
  line-height: 1.2;
  text-transform: uppercase;
}

/// Small label font style
@mixin small-label-font-styles($text-transform: none) {
  font-family: $sans-font-stack;
  color: $light-font-gray;
  font-weight: $global-weight-normal;
  font-size: 15px;
  text-transform: $text-transform;
}

/// Defaults for small item labels
/// Use this mixin on the container for the label/item
/// @param {String} $label-selector - Selector for the item label
/// @param {String} $item-selector - Selector for the item content
@mixin default-small-item-label($label-selector, $item-selector) {
  margin-top: 15px;
  @include breakpoint(medium) {
    margin-top: 20px;
  }
  &:first-child {
    margin-top: 0;
  }
  #{$label-selector} {
    position: relative;
    bottom: -2px;
    @include small-label-font-styles();
    line-height: 1;
  }
  #{$item-selector} {
    @include header-font-size(h5);
    line-height: 1.2;
  }
}

/// Block header style
@mixin block-header() {
  display: inline-block;
  @include block-header-font-styles();
  padding-bottom: 5px;
  border-bottom: 1px solid $light-font-gray;
  margin-bottom: 25px;
}
/// Small header font style
/// @param {String} $color [$light-font-gray] - Header font color
@mixin small-header($color: $light-font-gray) {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: $sans-font-stack;
  font-weight: 400;
  color: $color;
  text-transform: uppercase;
}

/// Schedule Header
@mixin schedule-header($text-transform: uppercase) {
  padding: 10px 10px;
  border-bottom: 2px solid $border-dark-gray;
  font-family: $sans-font-stack;
  text-transform: $text-transform;
  @include header-font-size(h4);
}


/// Tab height - Use this mixin to customize the CGA styled tab height. Include this mixin on the `.tab` class
/// @param {Number} $height [40] - Height of the tabs in pixels (unitless)
@mixin tab-height($height: 40, $offset: 6) {
  &:before {
    height: #{$height - $offset}px;
  }
  & > li {
    &.is-active > a:before {
      height: #{$height}px;
    }
    & > a:after,
    & > .tab-label:after {
      height: #{$height - $offset - 2}px;
    }

  }
}


/// Table Display Mixins - Table
@mixin table-style() {
  display: table;
  width: 100%;
  border-radius: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
/// Table Display Mixins - Table Header
@mixin table-header-style() {
  display: table-header-group;
}
/// Table Display Mixins - Table Body
@mixin table-body-style() {
  display: table-row-group;
}
/// Table Display Mixins - Table Row
@mixin table-header-row-style() {
  display: table-row;
}
/// Table Display Mixins - Table Row
@mixin table-row-style() {
  display: table-row;
  &:nth-child(odd) {
    background-color: whitesmoke;
  }
}
/// Table Display Mixins - Table Cell
@mixin table-cell-style() {
  display: table-cell;
  padding: 8px 10px 10px;
  font-size: 18px;
  line-height: 1.2;
}
/// Table Display Mixins - Table Header Cell
@mixin table-header-cell-style() {
  display: table-cell;
  padding: 8px 10px 10px;
  @include small-label-font-styles(uppercase);
}

/// For block views with only a sort exposed filter, use this to position the filter easily in a standard way. This
/// mixin should be used on the outer block wrapper.
/// @param {String} $sort-width [200px] - The width of the sort filter
/// @param {Boolean} $title-breakout-container [true] - Whether the title has a breakout container, used for positioning the filter
@mixin drupal-view-sort-align($sort-width: 200px, $title-breakout-container: true) {
  .block-title-container {
    @if ($title-breakout-container == true) {
      @include breakout-container($border: false, $padding: sides);
    }
    .block-title {
      margin-bottom: 0;
      @include breakpoint(medium) {
        padding-right: $sort-width;
        @include title-space(section-title);
      }
    }
  }
  .block-container {
    position: relative;
  }
  .views-exposed-widgets {
    display: inline-block;
    position: relative;
    margin: 0;
  }
  .views-exposed-form {
    margin: 0 0 20px 15px;
    @include breakpoint(medium) {
      position: absolute;
      top: -10px;
      @if ($title-breakout-container == true) {
        right: 35px;
      } @else {
        right: 0;
      }
      margin: 0;
    }
    .views-exposed-widget {
      padding: 0;
      select {
        margin: 0;
      }
    }
    .ajax-progress-throbber {
      position: absolute;
      right: -30px;
      bottom: 8px;
      @include breakpoint(medium) {
        left: -30px;
        right: auto;
      }
    }
  }
}

/// For the publication/bill exposed filter block, pull the sort out, so it is visually below
@mixin filter-pull-out-sort() {
  position: absolute;
  right: 0;
  bottom: -110px;
  width: 160px;
  padding: 0;
  @include breakpoint(medium) {
    bottom: -120px;
  }
  label {
    color: $body-font-color;
  }
}

/// Style the added clear-fields link to large exposed filter blocks
/// @param {String} $left [78px] - The left value of the clear fields link, this should place the link just past the submit button
@mixin clear-fields-link($left: 78px) {
  display: block;
  height: 40px;
  @include vertical-center();
  left: $left;
  padding: 12px 0 12px 25px;
  overflow: hidden;
  font-family: $sans-font-stack;
  color: $white;
  font-size: 14px;
  line-height: 1;
  opacity: 1;
  transition: opacity $toggle-transition-speed, visibility 0ms;
  transition-delay: 0ms, 0ms;
  &:before {
    @include icomoon();
    content: $icon-close;
    font-size: 18px;
    position: absolute;
    top: 11px;
    left: 0;
  }
}

/// Styles for the active trail triangle
@mixin active-trail($triangle-position: bottom, $color: $white) {
  position: relative;
  &:before {
    content: '';
    display: block;
    z-index: z(defined);
    @if ($triangle-position == bottom) {
      @include h-align-transform();
      @include triangle-shape(top, $color);
      bottom: 0;
    } @else if ($triangle-position == top) {
      @include h-align-transform();
      @include triangle-shape(bottom, $color);
      top: 0;
    } @else if ($triangle-position == left) {
      @include vertical-center();
      @include triangle-shape(right, $color);
      left: 0;
    } @else if ($triangle-position == right) {
      @include vertical-center();
      @include triangle-shape(left, $color);
      right: 0;
    }
  }
}

/// Helper mixin to get config based on menu type
/// @param {String} $menu-type [main] - The menu type to get preset active trail styles for [main|mobile|agency]
@mixin menu-active-trail($menu-type: main) {
  @if ($menu-type == main) {
    @include active-trail(bottom, $primary-color);
  } @else if ($menu-type == mobile) {
    @include active-trail(left, $white);
  } @else if ($menu-type == agency) {
    @include active-trail(bottom, $white);
  }
}
