////
/// Session Schedule
////

.main-content-section-container .node-advanced-page.node-12951.view-mode-full {
  margin-bottom: 10px;
}

.session-schedule-header {
  position: relative;
  @include breakpoint(medium) {
    height: 100px;
  }
  h4 {
    font-family: $sans-font-stack;
    line-height: 1.1;
    @include breakpoint(medium) {
      margin-right: 250px;
    }
  }
  .filters {
    @include breakpoint(medium) {
      position: absolute;
      top: -10px;
      right: 0;
    }
    .filter {
      display: inline-block;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
    select {
      width: auto;
    }
    label {
      @include small-label-font-styles();
    }
  }
  .week-nav {
    position: relative;
    a {
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
    }
    .ajax-progress {
      position: absolute;
      left: -18px;
      bottom: 2px;
    }
  }
  .next-week {
    @include icon-inline-attach($icon-arrow-right, 12, after);
  }
  .prev-week {
    @include icon-inline-attach($icon-arrow-left, 12, before);
  }
}


.session-schedule {
  // Show/Hide two views
  &.view-expanded {
    .session-schedule-tab-view {
      display: none;
    }
    .session-schedule-expanded-view {
      display: block;
    }
  }
  &.view-tabbed {
    .session-schedule-tab-view {
      display: block;
    }
    .session-schedule-expanded-view {
      display: none;
    }
  }
  .session-schedule-expanded-view {
    display: none;
  }
}

.session-schedule-content {

  .session-schedule-tab-view {
    .link-select {
      text-transform: capitalize;
      margin: 0;
      option {
        text-transform: capitalize;
      }
    }
    .tabs {
      @include tab-height(64);

      .tabs-title > a,
      .tab-label {
        padding: 10px 20px;
        span {
          display: block;
          font-size: 14px;
          line-height: 1;
          text-align: center;
          &.day {
            padding-bottom: 4px;
            font-size: 18px;
            text-transform: uppercase;
          }
        }
      }
    }
    .disabled-tab .tab-label {
      color: lighten($light-font-gray, 30%);
      &:hover {
        color: lighten($light-font-gray, 30%);
      }
    }
  }

  .session-schedule-expanded-view {
    .expanded-day {
      margin-bottom: 15px;
    }
    .expanded-header {
      @include schedule-header();
      @include breakpoint(medium) {
        margin-bottom: 5px;
      }
    }
    .expanded-content {
      margin-bottom: 30px;
    }
  }

  table {
    margin: 0;
  }

  .no-results {
    margin: 10px;
    @include breakpoint(medium) {
      margin: 0 10px 10px 10px;
    }
  }

}

.no-results {
  margin-bottom: 10px;
  font-family: $sans-font-stack;
  font-size: 16px;
}

.no-filter-results {
  padding: 8px 10px 10px;
  margin: 0;
  background-color: whitesmoke;
  font-family: $sans-font-stack;
  font-size: 16px;
  font-style: italic;
}

.view-interim-schedule{
  
  h3{
    border-bottom: 2px solid; 
  }
 tbody{
      tr{
        td:nth-child(2){
          width: 60%;
        }
      }
    }

}
