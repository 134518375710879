/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Accordion!
 *
 * * * * * * * * * * * * * * * * * * * * */

.accordion {
  .accordion-title {
    padding: 20px 30px 20px 15px;
    font-family: $sans-font-stack;
    h3,
    h4,
    h5 {
      font-family: $sans-font-stack;
    }
    & > * {
      margin: 0;
      font-size: 18px;
    }
    &:before {
      content: $icon-circle-arrow-down;
      @include icomoon();
      font-size: 20px;
      margin-top: -10px;
      transition: transform 250ms;
    }
    .count {
      font-size: 13px;
    }
  }

  .accordion-item {
    border-top: 1px solid $white;
    &:first-child {
      border-top: none;
    }
    &.is-active {
      .accordion-title {
        background-color: $primary-color;
        color: $white;
        &:before {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .accordion-content {
    padding-left: 0;
    padding-right: 0;
  }
}