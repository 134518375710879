.node-type-committee-detail {
  @include fullwidth-layout();
  header a{
    color: black;
  }
  .main-content-section {
    > .main-content {
      width: 100%;
      .field-name-field-content-title {
        @include schedule-header(capitalize);
      }
      .back-to-top {
        float: right;
        .scroll-to-top {
          @include icon-inline-attach($icon-arrow-up, $icon-size: 18, $position: after);
          color: $light-font-gray;
        }
      }
    }
  }
  .switcher-label {
    float: left;
    margin-right: 3%;
    line-height: 39px;
    @include small-label-font-styles(uppercase);
  }
  .session-switcher {
    width: 65%;
    @include breakpoint(medium) {
      width: 20%;
    }
  }
}
