/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Photoswipe custom styles
 *
 * * * * * * * * * * * * * * * * * * * * */


.photoswipe-gallery {
  .ps-image-wrapper {
    display: block;
    position: relative;
    //&:after {
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  height: 100%;
    //  width: 100%;
    //  top: 0;
    //  left: 0;
    //  transition: background-color 500ms;
    //  background-color: transparent;
    //}
    //&:hover:after {
    //  background-color: rgba(0, 0, 0, 0.3);
    //}
    img {
      display: block;
    }
  }
}

.pswp__share-tooltip {
  border-radius: 0;
  a:first-child,
  a:last-child {
    border-radius: 0;
  }
}