/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Select2 styles
 *
 * * * * * * * * * * * * * * * * * * * * */

.select2 {
  .select2-selection {
    border-radius: 0;
    line-height: 1.2;
    border-color: $form-border-color;
    transition: border-color $toggle-transition-speed;
    .select2-selection__choice {
      border-radius: 0;
      border-color: $primary-color;
      background-color: rgba($primary-color, 0.2);
    }
  }

  .select2-search {
    font-size: 16px;
    .select2-search__field {
      height: 28px;
      box-shadow: none;
      margin-top: 5px;
      line-height: 1.8;
      &::placeholder {
        color: black;
      }
    }
  }

  // on focus or open
  &.select2-container--open,
  &.select2-container--focus {
    .select2-selection {
      border-color: $form-border-focus-color;
    }
  }
}

// container
.select2-container {

  .select2-dropdown {
    border-radius: 0;
    border-color: $form-border-focus-color;
  }

  li {
    font-family: $sans-font-stack;
    font-size: 16px;
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary-color;
  }
}



// styles for the magnifying glass or something
#block-cga-legislators-legislator-search {

}