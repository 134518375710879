/* normalize.css 2012-03-11T12:53 UTC - http://github.com/necolas/normalize.css */

/* =============================================================================
   Normalize.css is intended to be used as an alternative to CSS resets.

   It is suggested that you read through the normalize.css file and customise it
   to meet the design requirements of a project rather including it as a "black
   box". @see http://nicolasgallagher.com/about-normalize-css/

   Also: @see http://meiert.com/en/blog/20080419/reset-style-sheets-are-bad/
         @see http://snook.ca/archives/html_and_css/no_css_reset/
   ========================================================================== */

/* =============================================================================
   Box Sizing
   ========================================================================== */
* {
  box-sizing: border-box;
}

/* =============================================================================
   HTML5 display definitions
   ========================================================================== */

/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block;
}

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */

audio,
canvas,
video {
  display: inline-block;
  // @if support-legacy-browser('ie', '7') {
  //   *display: inline;
  //   *zoom: 1;
  // }
}

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */

[hidden] {
  display: none;
}


/* =============================================================================
   Base
   ========================================================================== */

/* Instead of relying on the fonts that are available on a user's computer, you
   can use web fonts which, like images, are resources downloaded to the user's
   browser. Because of the bandwidth and rendering resources required, web fonts
   should be used with care.

   Numerous resources for web fonts can be found on Google. Here are a few
   websites where you can find Open Source fonts to download:
   - http://www.fontsquirrel.com/fontface
   - http://www.theleagueofmoveabletype.com

   In order to use these fonts, you will need to convert them into formats
   suitable for web fonts. We recommend the free-to-use Font Squirrel's
   Font-Face Generator:
     http://www.fontsquirrel.com/fontface/generator

   The following is an example @font-face declaration. This font can then be
   used in any ruleset using a property like this:  font-family: Example, serif;

   Since we're using Sass, you'll need to declare your font faces here, then you
   can add them to the font variables in the _base.scss partial.
*/

/*
@font-face {
  font-family: 'Example';
  src: url('../fonts/example.eot');
  src: url('../fonts/example.eot?iefix') format('eot'),
    url('../fonts/example.woff') format('woff'),
    url('../fonts/example.ttf') format('truetype'),
    url('../fonts/example.svg#webfontOkOndcij') format('svg');
  font-weight: normal;
  font-style: normal;
}
*/

/*
 * 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/*
 * Addresses font-family inconsistency between 'textarea' and other form elements.
 */

html,
button,
input,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

/*
 * Addresses margins handled incorrectly in IE6/7
 */

body {
  margin: 0;
  padding: 0;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  white-space: nowrap;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/

b,
strong {
  font-weight: bold;
}

/*
 * Addresses styling not present in S5, Chrome
 */

dfn {
  font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */

mark {
  background: #ff0;
  color: #000;
}


/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */

pre,
code,
kbd,
samp,
tt,
var {
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-size: 1em;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */

/* 1 */

q {
  quotes: none;
}

/* 2 */

q:before,
q:after {
  content: '';
  content: none;
}

/* @todo Determine how big element affects vertical rhythm. */
small {
  font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* =============================================================================
   Lists
   ========================================================================== */

/*
 * Corrects list images handled incorrectly in IE7
 */

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

/* =============================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Removes border when inside 'a' element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */

  /* vertical-align: bottom; */ /* Suppress the space beneath the baseline */

  /* Responsive images */
  /* @todo Add responsive embedded video. */
  max-width: 100%;
  height: auto;
}

/*
 * Corrects overflow displayed oddly in IE9
 */

svg:not(:root) {
  overflow: hidden;
}


/* =============================================================================
   Figures
   ========================================================================== */

/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */

figure {
  margin: 0;
}


/* =============================================================================
   Forms
   ========================================================================== */

/*
 * Corrects margin displayed oddly in IE6/7
 */

form {
  margin: 0;
}

/*
 * Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
 */

button,
input {
  line-height: normal; /* 1 */
}

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; /* 1 */
  -webkit-appearance: button; /* 2 */
  // @if support-legacy-browser('ie', '7') {
  //   *overflow: visible;  /* 3 */
  // }
}

/*
 * Re-set default cursor for disabled elements
 */

button[disabled],
input[disabled] {
  cursor: default;
}

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
      Known issue: excess padding remains in IE6
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; /* 2 */
  // @if support-legacy-browser('ie', '7') {
  //   *height: 13px; /* 3 */
  //   *width: 13px; /* 3 */
  // }
}

/*
 * 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  box-sizing: content-box;
}

/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/* =============================================================================
   Tables
   ========================================================================== */

/*
 * Remove most spacing between table cells
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}