/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Styles for functional elements
 *
 * * * * * * * * * * * * * * * * * * * * */


// Simple dropdown - prototyping
.simple-dropdown-container {
  display: inline-block;
  position: relative;
  .dropdown-toggle {

  }
  .dropdown {
    display: none;
    @include horizontal-center();
    top: 100%;
    margin-top: 10px;
    z-index: z(dropdowns, default);
  }

  &.dropdown-active {
    .dropdown {
      display: block;
    }
  }
}

.share-link-block {
  .share-links {
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;
    &:before {
      content: '';
      @include horizontal-center();
      top: -10px;
      @include triangle-shape(top, $primary-color);
    }
    a {
      display: block;
      position: relative;
      padding: 6px 12px 6px 35px;
      font-family: $sans-font-stack;
      font-size: 16px;
      color: $white;
      &:before {
        @include icomoon();
        position: absolute;
        @include vertical-center();
        left: 12px;
      }

      &.share-link-twitter {
        background-color: $primary-color;
        &:before {
          content: $icon-twitter;
        }
        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }
      &.share-link-facebook {
        background-color: $primary-color;
        &:before {
          content: $icon-facebook;
        }
        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }
      &.share-link-email {
        background-color: $primary-color;
        &:before {
          content: $icon-contact;
        }
        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }
    }
  }
}



.item-list .pager {
  a {
    color: $light-font-gray;
    font-family: $sans-font-stack;
  }
  .pager-current {
    font-family: $sans-font-stack;
    font-weight: normal;
  }
  .pager-item,
  .pager-current,
  .pager-ellipsis {
    margin: 0;
    padding: 8px 6px;
    font-size: 16px;
    @include breakpoint(medium) {
      margin: 0 5px;
      padding: 8px;
      font-size: 18px;
    }
  }
  .pager-ellipsis {
    margin: 0;
    padding: 0;
  }
  .pager-first {
    margin: 0 15px 0 0;
    display: none;
    @include breakpoint(medium) {
      display: inline-block;
    }
  }
  .pager-previous,
  .pager-next,
  .pager-item {
    position: relative;
    .ajax-progress {
      width: 18px;
      @include vertical-center();
    }
  }
  .pager-previous {
    margin: 0;
    a {
      @include icon-inline-attach($icon-arrow-left, 14);
      vertical-align: bottom;
    }
    @include breakpoint(small down) {
      padding-left: 0;
      a {
        width: 20px;
        overflow: hidden;
      }
    }
    @include breakpoint(medium) {
      margin: 0 15px 0 0;
    }
  }
  .pager-current {
    text-decoration: underline;
  }
  .pager-next {
    margin: 0;
    a {
      @include icon-inline-attach($icon-arrow-right, 14, after);
      vertical-align: bottom;
    }
    @include breakpoint(small down) {
      padding-right: 0;
      a {
        width: 20px;
        overflow: hidden;
        text-indent: 100px;
      }
    }
    @include breakpoint(medium) {
      margin: 0 0 0 15px;
    }
  }
  .pager-last {
    margin: 0 0 0 15px;
    display: none;
    @include breakpoint(medium) {
      display: inline-block;
    }
  }
}