/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All Search and Search Results styles
 *
 * * * * * * * * * * * * * * * * * * * * */

// Search page layout
.page-sitewide-search,
.page-sitewide-search-2,
.page-publication-search,
.page-audit-search,
.page-bills-by-bill-number,
.page-bill-search {
  @include fullwidth-layout();
  .main-content-section-container {
    @include content-width(breakout);
  }
}

// Generic search input block styles
#block-views-exp-publication-search-simple,
#block-views-exp-sitewide-search-full,
#block-views-exp-sitewide-search-full,
#block-views-exp-sitewide-search-2-full,
#block-views-exp-publication-search-full,
#block-views-exp-audit-search-audit-simple,
#block-views-exp-audit-search-audit-full,
#block-views-exp-bill-search-full,
#block-views-exp-bills-by-bill-number-page,
#block-views-22e0198d89a030430f43085c0ea5bb9f,
#block-views-exp-bill-search-simple,
#block-views-exp-bills-by-number-full {
  background-image: $image-search-bg;
  padding: 30px 0 40px 0;
  @include bg-cover();
  label {
    color: $white;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 5px;
  }
  input,
  select {
    margin-bottom: 0;
  }
  .views-exposed-widgets {
    margin: 0;
  }
  .views-exposed-widget {
    padding: 0 15px 15px 0;
  }
  .views-submit-button {
    position: relative;
    input {
      @include button($background: $secondary-color, $background-hover: darken($secondary-color, 5%));
      margin: 0;
    }
    .ajax-progress-throbber {
      display: block;
      @include vertical-center();
      left: 75px;
      height: 24px;
      width: 24px;
      .throbber {
        background-image: $ajax-throbber-alternate;
      }
    }
  }
}

/**
 * Search input blocks, custom styles
 */
// Simple bill search filter block
#block-views-exp-publication-search-simple,
#block-views-exp-audit-search-audit-simple,
#block-views-exp-bill-search-simple {
  $search-input-width: 70;
  .block-container {
    @include content-width(narrow);
  }
  .views-exposed-widgets {
    margin: 0;
    position: relative;
    .views-exposed-widget {
      padding-bottom: 0;
    }
    .views-widget-filter-search_api_views_fulltext {
      width: calc(100% - #{$search-input-width}px);
      input {
        margin: 0;
      }
    }
    .views-submit-button {
      width: #{$search-input-width}px;
      margin: 18px 0 0 0;
      padding: 0;
    }
  }
}
// TODO: Fix code to make the blue nav bar work without having to hand-code css for every single page.
#block-views-exp-bills-by-bill-number-page {
  & > div {
    @include content-width();
  }
  form {
    position: relative;
  }
  .views-submit-button {
    margin-top: 1.1em;
  }
}
// Full Bill search and Bills By Number filter block
#block-views-exp-bill-search-full,
#block-views-exp-sitewide-search-2-full,
#block-views-exp-bills-by-number-full {
  & > div {
    @include content-width();
  }
  form {
    position: relative;
  }
  .views-widget-filter-search_api_views_fulltext {
    width: 50%;
  }
  .views-widget-filter-field_chamber {
    width: 50%;
    padding-right: 0;
  }
  .views-widget-filter-field_bill_type {
    width: 50%;
  }
  .views-widget-filter-field_sessions {
    width: 50%;
    padding-right: 0;
  }
  .views-widget-filter-field_subjects {
    width: 100%;
    padding-right: 0;
  }
  .views-widget-sort-sort_bef_combine {
    @include filter-pull-out-sort();
  }
  .views-submit-button {
    padding: 0;
    width: 50%;
    margin-top: 18px;
    position: relative;
    .clear-fields {
      @include clear-fields-link();
    }
    .ajax-progress-throbber {
      display: block;
      @include vertical-center();
      left: 75px;
      height: 24px;
      width: 24px;
      .throbber {
        background-image: $ajax-throbber-alternate;
      }
      & + .clear-fields {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0ms, $toggle-transition-speed;
      }
    }
  }
  @include breakpoint(medium) {
    .views-widget-filter-search_api_views_fulltext {
      width: 32%;
    }
    .views-widget-filter-field_chamber {
      width: 23%;
      padding-right: 15px;
    }
    .views-widget-filter-field_bill_type {
      width: 20%;
    }
    .views-widget-filter-field_sessions {
      width: 25%;
      padding-right: 0;
    }
    .views-widget-filter-field_subjects {
      width: 75%;
      padding: 0 15px 0 0;
    }
    .views-submit-button {
      width: 25%;
    }
  }
  @include breakpoint(xlarge) {
    .views-widget-filter-search_api_views_fulltext {
      width: 20%;
      padding-bottom: 0;
    }
    .views-widget-filter-field_chamber {
      width: 12%;
      padding-bottom: 0;
    }
    .views-widget-filter-field_bill_type {
      width: 12%;
      padding-bottom: 0;
    }
    .views-widget-filter-field_sessions {
      width: 12%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_subjects {
      width: 27%;
    }
    .views-submit-button {
      width: 17%;
    }
  }
}

// Sitewide search
#block-views-exp-sitewide-search-2-full,
#block-views-exp-sitewide-search-full {
  $search-input-width: 90;
  & > div {
    //@include content-width(narrow);
  }
  .views-widget-filter-search_api_views_fulltext {
    padding-bottom: 0;
    width: calc(55% - #{$search-input-width/2}px);
    @include breakpoint(medium) {
      width: calc(60% - #{$search-input-width/2}px);
    }
  }
  .views-widget-filter-type {
    padding-bottom: 0;
    width: calc(45% - #{$search-input-width/2}px);
    @include breakpoint(medium) {
      width: calc(40% - #{$search-input-width/2}px);
    }
  }
  .views-submit-button {
    margin-top: 18px;
    padding: 0;
    width: 70px;
  }
}
// Full Publication search filter block
#block-views-exp-publication-search-full {
  & > div {
    @include content-width();
  }
  form {
    position: relative;
  }
  .views-widget-filter-search_api_views_fulltext {
    width: 50%;
  }
  .views-widget-filter-field_publication_type {
    width: 50%;
    padding-right: 0;
  }
  .views-widget-filter-field_date {
    width: 50%;
  }
  .views-widget-filter-field_subjects {
    width: 50%;
    padding-right: 0;
  }
  .views-widget-filter-field_agency {
    width: 50%;
  }
  .views-widget-sort-sort_bef_combine {
    @include filter-pull-out-sort();
  }
  .views-submit-button {
    width: 50%;
    margin-top: 18px;
    padding: 0;
    position: relative;
    .clear-fields {
      @include clear-fields-link();
    }
    .ajax-progress-throbber {
      display: block;
      @include vertical-center();
      left: 75px;
      height: 24px;
      width: 24px;
      .throbber {
        background-image: $ajax-throbber-alternate;
      }
      & + .clear-fields {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0ms, $toggle-transition-speed;
      }
    }
  }
  @include breakpoint(medium) {
    .views-widget-filter-search_api_views_fulltext {
      width: 40%;
    }
    .views-widget-filter-field_publication_type {
      width: 40%;
      padding-right: 15px;
    }
    .views-widget-filter-field_date {
      width: 20%;
      padding-right: 0;
    }
    .views-widget-filter-field_subjects {
      width: 45%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_agency {
      width: 27%;
      padding: 0 15px 0 0;
    }

    .views-submit-button {
      width: 27%;
    }
  }
  @include breakpoint(xlarge) {
    .views-widget-filter-search_api_views_fulltext {
      width: 18%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_publication_type {
      width: 13%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_date {
      width: 10%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_subjects {
      width: 27%;
    }
    .views-widget-filter-field_agency {
      width: 15%;
    }
    .views-submit-button {
      width: 17%;
    }
  }
}

// Full Audit search filter block
#block-views-exp-audit-search-audit-full {
  & > div {
    @include content-width();
  }
  form {
    position: relative;
  }
  .views-widget-filter-search_api_views_fulltext {
    width: 50%;
  }
  .views-widget-filter-field_publication_type {
    width: 50%;
    padding-right: 0;
  }
  .views-widget-filter-field_date {
    width: 50%;
  }
  .views-widget-filter-field_subjects {
    width: 50%;
    padding-right: 0;
  }
  .views-widget-filter-field_agency {
    width: 50%;
  }
  .views-widget-sort-sort_bef_combine {
    @include filter-pull-out-sort();
  }
  .views-submit-button {
    width: 50%;
    margin-top: 18px;
    padding: 0;
    position: relative;
    .clear-fields {
      @include clear-fields-link();
    }
    .ajax-progress-throbber {
      display: block;
      @include vertical-center();
      left: 75px;
      height: 24px;
      width: 24px;
      .throbber {
        background-image: $ajax-throbber-alternate;
      }
      & + .clear-fields {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0ms, $toggle-transition-speed;
      }
    }
  }
  @include breakpoint(medium) {
    .views-widget-filter-search_api_views_fulltext {
      width: 40%;
    }
    .views-widget-filter-field_publication_type {
      width: 40%;
      padding-right: 15px;
    }
    .views-widget-filter-field_date {
      width: 20%;
      padding-right: 0;
    }
    .views-widget-filter-field_subjects {
      width: 45%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_agency {
      width: 27%;
      padding: 0 15px 0 0;
    }

    .views-submit-button {
      width: 27%;
    }
  }
  @include breakpoint(xlarge) {
    .views-widget-filter-search_api_views_fulltext {
      width: 18%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_publication_type {
      width: 13%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_date {
      width: 10%;
      padding: 0 15px 0 0;
    }
    .views-widget-filter-field_subjects {
      width: 27%;
    }
    .views-widget-filter-field_agency {
      width: 15%;
    }
    .views-submit-button {
      width: 17%;
    }
  }
}
.view-bills-by-legislator.view-display-id-block,
.view-publications.view-display-id-related,
.view-sitewide-search,
.view-sitewide-search-2,
.view-publication-search,
.view-audit-search,
.view-bills-by-bill-number,
.view-bills-by-number,
.view-bill-search {
  .view-header {
    @include breakout-container($border: false, $padding: sides);
    margin-bottom: 30px;
    font-family: $sans-font-stack;
  }
  .views-row {
    @include breakout-container($border: false, $padding: sides);
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid $base-gray-medium;
  }
}
#block-cga-legislators-legistator-bills {
  .block-content > article {
    @include breakout-container($border: false, $padding: sides);
    padding: 30px 0;
    border-top: 1px solid $base-gray-medium;
    @include breakpoint(medium) {
    
    }
  }
}

.view-mode-search {
  .search-aside {
    margin-top: 10px;
  }
  @include breakpoint(medium) {
    @include grid-row($behavior: nest);
    .search-main {
      @include grid-column(8);
    }
    .search-aside {
      margin-top: 30px;
      @include grid-column(4);
    }
  }

  .search-result-single-item {
    margin-bottom: 10px;
    line-height: 1.4;
    @include breakpoint(medium) {
      margin-bottom: 15px;
    }
    label {
      line-height: 1.4;
      @include small-label-font-styles();
      display: inline;
      text-transform: uppercase;
    }
  }

  h3 {
    margin: 0 0 5px 0;
  }
  p {
    margin: 0;
  }
  .node-type {
    @include small-label-font-styles(uppercase);
  }
}
