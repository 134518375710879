/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Events (calendar_event and schedule_item)
 *
 * * * * * * * * * * * * * * * * * * * * */

.calendar-icon {
  @include calendar-icon();
}

.event-content {
  margin-left: 90px;
  position: relative;
  padding-top: 3px;
  .day-of-week {
    line-height: 1;
    font-family: $sans-font-stack;
    color: $light-font-gray;
    font-size: 15px;
  }
  .node-title {
    line-height: 1.2;
    margin: 0;
  }
  .event-meta {
    line-height: 1.2;
    font-size: 15px;
    color: $medium-font-gray;
    font-family: $sans-font-stack;
  }
  .field-name-field-event-documents {
    margin-top: 10px;
    .field-items {
      .field-item {
        display: inline-block;
        margin: 5px 10px 5px 0;
      }
    }
    a {
      @include default-cta-button();
      margin: 0;
    }
  }
  @include breakpoint(medium) {
    padding-right: 300px;
    .field-name-field-event-documents {
      margin-top: 0;
      width: 300px;
      @include vertical-center();
      right: 0;
      .field-items {
        text-align: right;
      }
      a {
        margin: 0;
      }
    }
  }
}

.node-calendar-event {
  &.node-teaser {
    position: relative;
    min-height: 90px;
    .calendar-view {
      position: absolute;
      top: 0;
      left: 0;
    }
    .calendar-event-content {

    }
  }
  &.view-mode-search {
    .event-content {
      min-height: 75px;
      position: relative;
      padding: 0 0 0 90px;
      margin: 0 0 10px 0;
    }
    .calendar-view {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.node-schedule-item {
  &.node-teaser {
    position: relative;
    .calendar-view {
      position: absolute;
      top: 0;
      left: 0;
    }
    .schedule-item-content {
      min-height: 75px;
    }
    .documents {
      @include breakpoint(large) {
        position: absolute;
        width: 400px;
        top: 0;
        right: 0;
        text-align: right;
        .button {
          margin: 0 0 10px 10px;
        }
      }
    }
  }
}