
.lt-ie10 {
  #block-views-exp-sitewide-search-full,
  #block-views-exp-publication-search-full,
  #block-views-exp-bill-search-full {
    .views-submit-button {
      .ajax-progress-throbber {
        .throbber {
          background: none;
          &:before {
            content: 'Searching';
            color: $white;
            font-family: $sans-font-stack;
            font-size: 14px;
          }
        }
      }
    }
  }
}
