/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Menu styles
 *
 * * * * * * * * * * * * * * * * * * * * */

ul.sidebar-menu {
  li {
    &.is-active-trail {
      background: #fafafa;
    }
    &.first{
      border-top: 0;
    }
    border-top: 1px solid #dedede;
  }
  border: 1px solid #dedede;
}
