/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Styles specific to accessibility functionality
 *
 * When editing stuff in this partial, make sure to test
 * that these features still work!
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Skip to content link
 */
#skip-to-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  z-index: z(tippy-top);
  .skip-to-content-link {
    display: inline-block;
    position: absolute;
    width: 160px;
    background: $secondary-color;
    color: $white;
    padding: 5px;

    left: -1000px;
    opacity: 0;
    transition-property: opacity, left;
    transition-duration: 600ms, 0ms;
    transition-delay: 1000ms, 1600ms;
    &:focus {
      left: 0;
      opacity: 1;
      transition-property: opacity;
      transition-duration: 0ms;
      transition-delay: 0ms;
    }
  }
}

// Adjust style for focusable divs
#main-content {
  transition: background 500ms;
  &:focus {
    background: lighten($secondary-color, 40%);
    outline: none;
  }
}


/**
 * Scroll and mobile menu accessibility
 *
 * We want to remove these menus from tab order when they are not visible and use visibility
 * as a transitionable property with delay to handle that, so we don't break the menu transitions
 * on interaction.
 *
 * We are also hiding the page when the menu is open, therefore, a keyboard user won't get lost past the end of
 * the menu where they can't see their current focus.
 */
.scroll-menu-wrapper {
  .agency-scroll-menu,
  .main-scroll-menu {
    visibility: hidden;
    transition: visibility 0ms;
    transition-delay: $menu-scroll-slide-speed;
  }
  &.active-menus {
    .agency-scroll-menu {
      visibility: visible;
      transition-delay: 0ms;
    }
    &.show-main-menu {
      .main-scroll-menu {
        visibility: visible;
        transition-delay: 0ms;
      }
    }
  }
}
#page,
.block-twitter-block iframe {
  transition: visibility 0ms;
  transition-delay: 0ms;
}
.main-mobile-menu-open,
.agency-mobile-menu-open {
  #page {
    visibility: hidden;
    transition-delay: $menu-scroll-slide-speed;
  }
  .slick-slider .slick-center .slide-content .content-panel {
    visibility: inherit;
  }
  .block-twitter-block iframe {
    // override the twitter embed inline style
    visibility: inherit !important;
    transition-delay: $menu-scroll-slide-speed;
  }
}
.mobile-menu-bar,
.mobile-menu-wrapper {
  // These styles are in _menus.scss since multiple transitions must be defined on the elements
}