/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * All paragraphs styles
 *
 * * * * * * * * * * * * * * * * * * * * */


// Some default prototyping styles


// Blocks placed in the aside region, and the main content area
.main-content .block-cga-blocks,
.region-aside > .block-cga-blocks {
  //@include callout($color-block-bg);
}

.region-bottom {
  // Blocks placed in the bottom region
  & > .block-cga-blocks,
  & > .block-bean {
    //@include callout($color-block-bg);
  }
  // Paragraph items in the bottom region
  .entity-paragraphs-item {
    //@include callout($color-paragraph-item-bg);
  }
  // Paragraph layouts in the bottom region
  .paragraphs-item-layout-status,
  .paragraphs-item-layout-two-col-feature,
  .paragraphs-item-layout-three-col,
  .paragraphs-item-layout-four-col {
    //@include callout($color-paragraph-layout-bg);
  }
}




/**
 * Simple Paragraphs
 */
//

// SP: Added 8-31-2016 to fix answers at agencies/senate/legislative-qa 
.faqfield-answer {
font-family: $sans-font-stack;
}

.paragraphs-item-simple-basic {
  .field-name-field-title .field-item {
    @include header-font-size(h3);
    @include title-space(section-title);
    font-family: $sans-font-stack;
  }
  .field-name-field-p-dynamic-link a {
    @include default-cta-button();
  }
}

.paragraphs-item-simple-2-col-copy {
  .field-name-field-title .field-item {
    @include header-font-size(h3);
    @include title-space(section-title);
    font-family: $sans-font-stack;
  }
  .field-name-field-body .field-item {
    columns: 300px 2;
    column-gap: 30px;
  }
}

.paragraphs-item-simple-directory-item {
  .field-name-field-title .field-item {
    @include header-font-size(h4);
  }
  .field-name-field-position .field-item {
    font-size: 18px;
    margin-bottom: 5px;
    font-family: $sans-font-stack;
    color: $light-font-gray;
  }
  .field-name-field-email a {
    text-decoration: underline;
  }
}

.paragraphs-item-simple-image-callout {
  .field-name-field-title .field-item {
    @include header-font-size(h3);
  }
  .image-callout-image {
    img {
      display: block;
    }
  }
  .image-callout-content {
    padding: 20px 25px 25px 25px;
    background: $primary-color;
    color: $white;
    .field-name-field-title {
      .field-item {
        font-family: $sans-font-stack;
        margin-bottom: 5px;
      }
    }
  }

}

.paragraphs-item-simple-image-gallery-item {
  margin: 0;
}

.paragraphs-item-simple-cta {
  a {
    display: block;
  }
  .field-name-field-title {
    text-align: center;
    .field-item {
      margin-top: 15px;
      font-family: $sans-font-stack;
      font-size: 16px;
      line-height: 1.2;
      color: $medium-font-gray;
      transition: color $default-transition-length;
      @include breakpoint(medium) {
        font-size: 20px;
      }
      @include breakpoint(large) {
        font-size: 20px;
      }
    }
  }
  .snap-icon-container {
    margin: 0 auto;
    max-width: 110px;
    @include breakpoint(medium) {
      max-width: 130px;
    }
  }
  & > .content > a:hover,
  & > .content > a:focus {
    .field-name-field-title .field-item {
      color: $secondary-color;
    }
    .snap-icon {
      .icon-bg {
        circle {
          fill: $light-gray;
        }
      }
    }
  }
}

.paragraphs-item-simple-link-bar-item {
  @include breakout-container();
  & > .content {
    position: relative;
    @include breakpoint(large) {
      padding-right: 140px;
    }
  }
  .field-name-field-title .field-item {
    @include header-font-size(h4);
    color: $primary-color;
  }
  .field-name-field-label .field-item {
    @include small-header();
  }
  .field-name-field-p-dynamic-link {
    a {
      @include default-cta-button();
      margin: 10px 0 0 0;
    }
    @include breakpoint(large) {
      max-width: 130px;
      @include vertical-center();
      right: 0;
      a {
        margin: 0;
      }
    }
  }
}

.paragraphs-item-simple-promoted-item {
  & > .content {
    position: relative;
  }
  .field-name-field-cta-icon {
    width: 60px;
    position: absolute;
    top: 0;
    left: 0;
    @include breakpoint(medium) {
      @include v-align-transform();
      width: 140px;
    }
    .snap-icon {
      position: relative;
      top: -10px;
    }
  }
  .field-name-field-cta-icon + .promoted-item-content {
    margin-left: 65px;
    @include breakpoint(medium) {
      min-height: 140px;
      margin-left: 180px;
    }
  }
  .field-name-field-title {
    font-family: $sans-font-stack;
    @include header-font-size(h3);
  }
  .field-name-field-p-2-dynamic-links {
    margin-top: 15px;
    & > .field-items > .field-item {
      display: inline-block;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      @include default-cta-button(gold);
    }
  }
}

.paragraphs-item-simple-main-slider-item {
  .slide-content {
    @include bg-cover();
    height: 400px;
    .content-panel{
      background: url("/sites/all/themes/themekit/images/transparent.png");
    }
  }
  .content-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    background-color: $base-gray;
    color: $white;
    width: 100%;
    @include breakpoint(medium) {
      bottom: 0px;
      left: 0px;
    }
    .field-name-field-title {
      .field-item {
        @include header-font-size(h2);
        line-height: 1.1;
        @include breakpoint(small down) {
          font-size: 24px;
          line-height: 1;
        }
      }
    }
    .field-name-field-short-description {
      .field-item {
        @include header-font-size(h5);
        font-family: $sans-font-stack;
        margin-top: 10px;
        @include breakpoint(small down) {
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }
    .field-name-field-cta {
      a {
        @include default-cta-button(gold);
        margin: 15px 0 0 0;
      }
    }
  }
}


/**
 * Component Paragraphs
 */
.paragraphs-item-component-feature {
  .field-name-field-title .field-item {
    @include header-font-size(h3);
    margin-bottom: 10px;
    @include breakpoint(medium) {
      @include title-space(section-title);
    }
  }
  .field-name-field-p-dynamic-link a {
    @include default-cta-button();
  }
  .component-feature {
    .feature-image {
      margin-bottom: 10px;
    }
    @include breakpoint(medium) {
      @include grid-row($behavior: nest);
      .feature-image {
        @include grid-column(6);
        margin-bottom: 0;
      }
      .feature-content {
        @include grid-column(6);
      }
    }
  }
}

.paragraphs-item-component-image-gallery {
  $gallery-gutter: 10px;
  @include content-width(default);
  .field-name-field-title .field-item {
    @include header-font-size(h3);
    @include title-space(section-title);
    font-family: $sans-font-stack;
  }
  .slick-slider {
    margin: 0 -#{$gallery-gutter};
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: $gallery-gutter;
      background-color: $body-background;
      z-index: z(defined);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  .photoswipe-image-gallery-item {
    padding: 0 $gallery-gutter;
  }
}

.paragraphs-item-component-link-bar {
  @include content-width(breakout);
  .component-link-bar {
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: '.field-item', $bottom-gutter: 15, $gutter: 30px);
    }
    & > .field-item {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
      @include breakpoint(medium) {
        margin-top: 0;
      }
    }
  }
}

.paragraphs-item-component-content-bar {
  .component-content-bar {
    & > .field-item {
      margin-bottom: 20px;
    }
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: '.field-item', $bottom-gutter: 15, $gutter: 30px);
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: '.field-item', $bottom-gutter: 15, $gutter: 30px);
    }
  }
}

.paragraphs-item-component-block-bar {
  .component-block-bar {
    & > .field-item {
      margin-bottom: 20px;
    }
  }
  &.col-number-1 {
    @include content-width(breakout);
    .component-block-bar {
      max-width: 400px;
      margin: 0 auto;
    }
  }
  &.col-number-2 {
    @include content-width(breakout);
    .component-block-bar {
      @include breakpoint(medium) {
        @include block-grid(2, '.field-item');
      }
      @include breakpoint(large) {
        max-width: 800px;
        margin: 0 auto;
      }
    }
  }
  &.col-number-3 {
    @include content-width(breakout);
    .component-block-bar {
      @include breakpoint(medium) {
        @include block-grid(2, '.field-item');
      }
      @include breakpoint(large) {
        @include block-grid(3, '.field-item');
      }
    }
  }

  .component-block-bar > .field-item > .entity-paragraphs-item:not(.paragraphs-item-reference-block) {
    @include breakout-container();
  }
  .paragraphs-item-reference-block {
    .block:not(.block-twitter-block) {
      @include breakout-container();
    }
  }
  .field-name-field-p-8-dynamic-links > .field-label {
    @include block-header();
    font-weight: $global-weight-normal;
  }
}

.paragraphs-item-component-secondary-body {
  @include content-width(default);
  & > .content > .field-name-field-title .field-item {
    @include header-font-size(h2);
    @include title-space(section-title);
    font-family: $sans-font-stack;
  }
  .field-name-field-p-secondary-body-items > .field-items > .field-item {
    @include vertical-space(medium);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.paragraphs-item-component-cta-bar {
  .component-cta-bar {
    @include block-grid($number: 2, $selector: 'div');
  }
  &.col-number-4 {
    .field-name-field-p-ctas {
      @include content-width(default);
    }
    .component-cta-bar {
      @include breakpoint(medium) {
        @include block-grid($number: 4, $selector: 'div');
      }
    }
  }
  &.col-number-3 {
    .field-name-field-p-ctas {
      max-width: 900px;
      margin: auto;
      .component-cta-bar {
        @include breakpoint(medium) {
          @include block-grid($number: 3, $selector: 'div');
        }
      }
    }
  }
  &.col-number-2 {
    .field-name-field-p-ctas {
      max-width: 600px;
      margin: auto;
    }
  }
  &.col-number-1 {
    .field-name-field-p-ctas {
      max-width: 400px;
      margin: auto;
      .component-cta-bar {
        @include block-grid($number: 1, $selector: 'div');
      }
    }
  }
}

.paragraphs-item-component-directory {
  & > .content > .field-name-field-title .field-item {
    @include header-font-size(h3);
    @include title-space(section-title);
    font-family: $sans-font-stack;
  }
  .field-name-field-p-directory-items > .field-items {
    & > .field-item {
      margin-bottom: 15px;
    }
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: '.field-item', $bottom-gutter: 20, $gutter: 30px);
    }
    @include breakpoint(large) {
      @include block-grid($number: 3, $selector: '.field-item', $bottom-gutter: 15, $gutter: 30px);
    }
  }
}

.paragraphs-item-component-callout-bar {
  .component-callout-bar {
    & > .field-item {
      margin-bottom: 15px;
    }
    @include breakpoint(medium) {
      @include block-grid($number: 2, $selector: '.field-item', $bottom-gutter: 15, $gutter: 30px);
    }
  }
}

.paragraphs-item-component-promoted {
  background-image: $image-capitol-bg;
  @include bg-cover();
  .component-promoted .paragraphs-item-simple-promoted-item {
    @include content-width(tighten);
    padding-top: 40px;
    padding-bottom: 40px;
    color: $white;
    @include breakpoint(medium) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}

.paragraphs-item-component-main-slider {
  overflow: hidden;
  padding-bottom: 20px;
  .slick-slider {
    opacity: 0;
    &.slick-initialized {
      opacity: 1;
    }
  }
  .slick-prev,
  .slick-next {
    opacity: 0;
    &:focus {
      opacity: 1;
    }
  }
}


/**
 * Layout Paragraphs
 */

// Style for the Three Column Content/Block paragraph layout
.paragraphs-item-layout-three-col {
  position: relative;
  .field-name-field-p-content-items > .field-items {
    @include grid-row($behavior: nest);
    > .field-item {
      @include grid-column(12);
      @media #{$medium-up} {
        @include grid-column(4);
      }
    }
  }
  &.col-number-2 {
    .field-name-field-p-content-items > .field-items > .field-item {
      @media #{$medium-up} {
        @include grid-column(6);
      }
    }
  }
  &.col-number-1 {
    .field-name-field-p-content-items > .field-items > .field-item {
      @include grid-column(12);
      max-width: 680px;
      margin: auto;
      float: none;
    }
  }
}


// Style for the Four Column CTA paragraph layout
.paragraphs-item-layout-four-col {
  .layout-four-col-cta {
    @include breakpoint(small) {
      @include block-grid($number: 2, $selector: 'div');
    }
  }
  &.col-number-4 {
    .layout-four-col-cta {
      @include breakpoint(medium) {
        @include block-grid($number: 4, $selector: 'div');
      }
    }
  }
  &.col-number-3 {
    .field-name-field-paragraph-cta {
      max-width: 900px;
      margin: auto;
      .layout-four-col-cta {
        @include breakpoint(medium) {
          @include block-grid($number: 3, $selector: 'div');
        }
      }
    }
  }
  &.col-number-2 {
    .field-name-field-paragraph-cta {
      max-width: 600px;
      margin: auto;
    }
  }
  &.col-number-1 {
    .field-name-field-paragraph-cta {
      max-width: 400px;
      margin: auto;
      .layout-four-col-cta {
        @include block-grid($number: 1, $selector: 'div');
      }
    }
  }
}


// Style for the Two Column with optional feature
.paragraphs-item-layout-two-col-feature {
  .layout-two-col-feature {
    @include grid-row($behavior:nest);
    & > .field-item {
      @include grid-column(12);
    }
  }
  &.col-number-2.no-feature {
    .layout-two-col-feature {
      & > .field-item {
        @include breakpoint(large) {
          @include grid-column(6);
        }
      }
    }
  }
  &.col-number-2.feature-first {
    .layout-two-col-feature {
      & > .item-0 {
        @include breakpoint(large) {
          @include grid-column(8);
        }
      }
      & > .item-1 {
        @include breakpoint(large) {
          @include grid-column(4);
        }
      }
    }
  }
  &.col-number-2.feature-second {
    .layout-two-col-feature {
      & > .item-0 {
        @include breakpoint(large) {
          @include grid-column(4);
        }
      }
      & > .item-1 {
        @include breakpoint(large) {
          @include grid-column(8);
        }
      }
    }
  }
}



// Styles for the status paragraph layout
//.paragraphs-item-layout-status {
.field-name-field-p-statuses {
  .layout-status {
    overflow: hidden;
    .status-item {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
      & > .paragraphs-item-component-status {
        & > .content {
          padding: 15px 10px;
          background: $lightest-gray;
          position: relative;
          border: 2px solid $light-gray;
          font-size: 22px;
          .field-name-field-label .field-item {
            line-height: 1.1;
            color: $primary-color;
            text-align: center;
          }
        }
      }
    }
    @include breakpoint(medium) {
      @include block-grid($number: 3, $selector: '.status-item', $bottom-gutter: 0);
      .status-item {
        margin: 0;
        & > .paragraphs-item-component-status > .content {
          height: 60px;
          padding: 5px 10px;
          border-top: 2px solid $light-gray;
          border-bottom: 2px solid $light-gray;
          border-left: none;
          border-right: none;
          &:before,
          &:after {
            content: '';
            position: absolute;
            z-index: z(bill-status, under-arrow);
            display: block;
            top: -3px;
            right: -30px;
            border-left: 30px solid $light-gray;
            border-top: 31px solid transparent;
            border-bottom: 31px solid transparent;
          }
          &:after {
            right: -27px;
            border-left: 30px solid $lightest-gray;
          }
          .field-name-field-label {
            position: relative;
            width: 100%;
            height: 46px;
            .field-item {
              @include vertical-center();
              width: 100%;
            }
          }
        }
        .large-triangle {
          position: absolute;
          width: 30px;
          height: 60px;
          left: -15px;
          top: -2px;
          background: $lightest-gray;
          border-top: 2px solid $light-gray;
          border-bottom: 2px solid $light-gray;
          // Down arrow
          &:before,
          &:after {
            content: '';
            position: absolute;
            display: block;
            top: -2px;
            left: 0;
            border-left: 30px solid $light-gray;
            border-top: 30px solid transparent;
            border-bottom: 30px solid transparent;
          }
          // Right arrow
          &:after {
            left: -3px;
            border-left: 30px solid $white;
          }
        }
      }
      .status-item {
        position: relative;
      }
      .status-item-1 {
        z-index: z(bill-status, top);
        .large-triangle {
          display: none;
        }
        .paragraphs-item-component-status > .content {
          border-left: 2px solid $light-gray;
        }
      }
      .status-item-2 {
        z-index: z(bill-status, middle);
        .paragraphs-item-component-status > .content {
          padding-left: 20px;
        }
      }
      .status-item-3 {
        .paragraphs-item-component-status > .content {
          padding-left: 20px;
          border-right: 2px solid $light-gray;
          &:before,
          &:after {
            display: none;
          }
        }
      }
      .status-item .paragraphs-item-component-status.status-final > .content {
        padding-left: 20px;
        border-right: 2px solid $light-gray;
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
}
