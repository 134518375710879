/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Bill specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Bill detail pages
 */
.node-type-bill {
  .header-wrapper {
    //@include vertical-space(default);
  }
  .main-content-section {
    @include vertical-space(medium);
  }
  .node-bill {
    @include vertical-space(small);
    .node-title {
      margin-bottom: 10px;
      font-size: 2.2rem;
    }
    .field-name-field-bill-number .field-item {
      @include block-header-font-styles();
    }
    .field-name-field-bill-long-title .field-item {
      @include sub-header-font-styles();
      font-family: $serif-font-stack;
      margin-bottom: 25px;
    }
    .bill-subjects-label,
    .bill-session-label {
      @include small-label-font-styles(uppercase);
    }
    .bill-subjects,
    .bill-session {
      margin-bottom: 25px;
    }
    .field-name-field-sessions,
    .field-name-field-subjects {
      font-family: $sans-font-stack;
      @include inline-drupal-field-items();
      a {
        font-size: 15px;
      }
    }
    .field-name-field-bill-summary {
      font-size: 18px;
    }
  }
  #block-cga-bills-bill-documents{
    #bill-documents-tabs8{
      table{
        thead{
          tr{
            th:first-child{
              width:  150px;
            }
          }
        }
      }
    }
  }
  .field-name-field-bill-summary {
    position: relative;
    overflow: hidden;
  }
  .field-name-field-bill-summary .read-more {
    visibility: hidden;
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%; 
    text-align: center; 
    margin: 0; padding: 0 0; 
    padding-top: 100px;
    background-image: linear-gradient(to bottom, transparent, white);
		a:after {
			background-color: red;
		}
  }
}

.node-bill.view-mode-search {

  .field-name-field-bill-number {
    line-height: 1;
    @include small-label-font-styles();
  }
  .bill-sponsors,
  .bill-type,
  .bill-session,
  .bill-subjects,
  .bill-last-action {
    label {
      line-height: 1.3;
      @include small-label-font-styles();
      display: inline;
      text-transform: uppercase;
    }
    span {
      font-family: $sans-font-stack;
    }
    .field {
      @include inline-drupal-field-items();
      font-family: $sans-font-stack;
    }
  }
}

.node-bill.view-mode-teaser_short {
  .field-name-field-bill-number .field-item {
    @include small-label-font-styles();
  }
}

.node-27 {
  .node-title {
    font-size: 3.4rem !important;
  }
}

.page-node-27 {
  .main-content-section{
      margin-bottom: 40px;
  }
  .main-content-section-container{
      margin-top: 40px;
  }
}


