/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Styles for the committee content type
 *
 * * * * * * * * * * * * * * * * * * * * */

.node-type-committee {
  .view-mode-full {
    h1.node-title {
      margin-bottom: 25px;
    }
  }
  .switcher-label {
    float: left;
    margin-right: 3%;
    line-height: 39px;
    @include small-label-font-styles(uppercase);
  }
  .session-switcher {
    width: 65%;
    @include breakpoint(medium) {
      width: 32%;
    }
  }
  .committee-subjects-label {
    @include small-label-font-styles(uppercase);
  }
  .committee-subjects {
    margin-bottom: 25px;
  }
  .field-name-field-subjects {
    @include inline-drupal-field-items();
    a {
      font-size: 15px;
    }
  }
  .field-name-field-committee-detail-link {
    .field-item {
      @include default-cta-button($type: gold);
      margin-right: 2%;
      a {
        color: white;
      }
    }
  }
}

.node-committee.view-mode-search {
  .field-name-field-session, .field-name-field-chambers, .field-name-field-committee-type {
    display: inline;
    div {
      display: inline;
    }
  }
  .field-name-field-subjects {
    @include inline-drupal-field-items();
  }
}

.view-committees-overview, .view-chamber-committees-overview  {
  table tr{
    .col-1, .col-2{
      width: 50%;
    }
    background-color: transparent !important;
    a {
       font-size: 1.5625rem;
       font-family: $serif-font-stack !important; 
    }
  }
}

#block-views-committee-audio-main{
  .col-1, .col-2{
    width: 50%;
  }
}

