/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * jQuery UI styles
 *
 * * * * * * * * * * * * * * * * * * * * */


#ui-datepicker-div {
  $datepicker-border: $border-dark-gray;
  $datepicker-bg: $white;
  background-color: $datepicker-bg;
  border: 1px solid $datepicker-border;
  border-top: none;
  z-index: 10 !important;
  padding: 0;
  border-radius: 0;
  td,
  span,
  select {
    font-family: $sans-font-stack;
    font-weight: normal;
  }
  th {
    color: darken($body-font-color, 5%);
  }
  .ui-datepicker-calendar {
    border-color: $datepicker-border;
    thead {
      background: darken($datepicker-bg, 5%);
    }
    tbody {
      border-color: $datepicker-border;
    }
  }
  .ui-datepicker-header {
    position: relative;
    border: none;
    background: $primary-color;
    border-radius: 0;
    padding: 5px 0;
    .ui-datepicker-prev,
    .ui-datepicker-next {
      @include vertical-center();
      .ui-icon {
        font-size: 14px;
        padding-left: 16px;
        background-image: none;
        text-indent: 0;
        &:before {
          color: $white;
          font-weight: normal;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          height: 14px;
          width: 14px;
          line-height: 14px;
          text-align: center;
        }
        &:hover {
          background-image: none;
        }
      }
      &.ui-state-hover {
        border: none;
        background: transparent;
        font-weight: normal;
        color: $white;
      }
      &.ui-datepicker-next {
        right: 0;
      }
      &.ui-datepicker-prev {
        left: 0;
      }
      &.ui-datepicker-next.ui-datepicker-next-hover,
      &.ui-datepicker-prev.ui-datepicker-prev-hover {
        border: none;
        border-radius: 0;
        background: transparent;
      }
    }
    .ui-datepicker-prev {
      .ui-icon:before {
        content: $icon-arrow-left;
        @include icomoon();
      }
      &.ui-datepicker-prev-hover {
        border-color: $datepicker-border;
        background: darken($datepicker-bg, 2%);
      }
    }
    .ui-datepicker-next {
      .ui-icon:before {
        content: $icon-arrow-right;
        @include icomoon();
      }
      &.ui-datepicker-next-hover {
        border-color: $datepicker-border;
        background: darken($datepicker-bg, 2%);
      }
    }
    .ui-datepicker select.ui-datepicker-month {
      width: 40%;
    }
    .ui-datepicker select.ui-datepicker-year {
      width: 55%;
    }
    select {
      //@include default-input-style();
      //@include select-bg();
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 14px;
      height: 30px;
    }
  }
  .ui-datepicker-calendar {
    padding: 0 5px 5px 5px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    thead {
      background-color: $datepicker-bg;
      span {
        text-transform: capitalize;
      }
    }
    tbody,
    tr {
      background-color: $datepicker-bg;
    }
    td {
      padding: 2px;
      font-size: 14px;
      a {
        text-align: center;
      }
    }
  }
  .ui-state-default {
    background: $base-gray-light;
    border: none;
    &.ui-state-hover {
      background-color: darken($base-gray-medium, 5%);
    }
  }
  &.ui-widget-content .ui-state-highlight {
    background: $primary-color;
    color: $white;
  }
  &.ui-widget-content .ui-state-active {
    background: $white;
    border: 1px solid darken($base-gray-medium, 35%)
  }

}