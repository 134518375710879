/* * * * * * * * * * * * * * * * * * * * *
 *
 * @file
 * Bill specific styles
 *
 * * * * * * * * * * * * * * * * * * * * */


/**
 * Bill detail pages
 */
.node-type-ballot {
  .header-wrapper {
    //@include vertical-space(default);
  }
  .main-content-section {
    @include vertical-space(medium);
  }
  .node-ballot {
    @include vertical-space(small);
    .node-title {
      margin-bottom: 1em;
      font-size: 2.2rem;
    }

    .field-name-field-election-year-title {
      margin-bottom: 0.8em;
    }
    .field-name-field-ballot-reference {
      @include block-header-font-styles();
    }
    .field-name-field-bill-long-title .field-item {
      @include sub-header-font-styles();
      font-family: $serif-font-stack;
      margin-bottom: 25px;
    }
    .ballot_files {
      span.ballot_label {
        @include sub-header-font-styles();
      }
      .field-label {
        margin-bottom: 1em;
        margin-top: 0.7em;
      }
    }
    table.no-striping tr {
      background: inherit;
    }
    table.borders {
      td, th {
        border: 1px #ababab solid;
      }
    }
    .field-email {
      .field-email-label {
        @include small-label-font-styles(uppercase);
      }        
      span.email {
        text-decoration: lowercase;
      }
      @include inline-drupal-field-items();
      margin-bottom: 1em;
    }
    .bill-subjects-label,
    .email-label {
      @include sub-header-font-styles();
    }
    .bill-subjects,
    .bill-session {
      margin-bottom: 25px;
    }
    .field-name-field-sessions,
    .field-name-field-subjects {
      font-family: $sans-font-stack;
      @include inline-drupal-field-items();
      a {
        font-size: 15px;
      }
    }
    .field-name-field-bill-summary {
      font-size: 18px;
    }
  }
}
article.node-ballot, article.node-initiative {
  .search-aside {
    .search-result-single-item label  {
      padding-right: 0.4em;
    }
  }
}



